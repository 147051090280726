import { PayPalButton } from "react-paypal-button-v2";


const Test = () => {

    // const { amount, currency, createSubscription, onApprove, catchError, onError, onCancel } = props;
    const paypalKey = "AeqdqOSNCAUbsrumuTllj2X0aqNePqUuVmPQWMQlfiXZzzxJ9fAznefjnrilZJZ0fo3ui4Tu_Zx7KI32" // TEST
    // const paypalKey = "ATn9A60_ObOBAENjRwZLRckn7eSJwz9R8ss_lQm1BwU-neXTmkChgVbvFkel-foLcTtmfTtaPStGbccI" // production
    return (
        <PayPalButton
            amount={50}
            currency={'EUR'}
            createOrder={(data, actions) => {
                console.log('createOrder', { data, actions });
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            currency_code: "EUR",
                            value: "50.00",
                            breakdown: {
                                item_total: {
                                    currency_code: "EUR",
                                    value: "50.00"
                                }
                            }
                        },
                        custom_id: '123test',
                        description: "test description",
                        invoice_id: "invoice123",
                        items: [{
                            name: 'book 1',
                            quantity: 2,
                            unit_amount: {
                                currency_code: "EUR",
                                value: "20.00"
                            },
                            // category: 'PHYSICAL_GOODS',
                            description: "vety good book",
                            sku: '10/100'
                        },
                        {
                            name: 'book 2',
                            quantity: 1,
                            unit_amount: {
                                currency_code: "EUR",
                                value: "10.00"
                            },
                            category: 'PHYSICAL_GOODS',
                            description: "very good book 2",
                            sku: 65
                        }
                        ],
                        soft_descriptor: "G123456789",
                        reference_id: "reference_123",
                    }, {
                        amount: {
                            currency_code: "EUR",
                            value: "40.00",
                            breakdown: {
                                item_total: {
                                    currency_code: "EUR",
                                    value: "40.00"
                                }
                            }
                        },
                        custom_id: '1232test',
                        description: "test description",
                        invoice_id: "invoice1232",
                        items: [{
                            name: 'book 12',
                            quantity: 2,
                            unit_amount: {
                                currency_code: "EUR",
                                value: "10.00"
                            },
                            // category: 'PHYSICAL_GOODS',
                            description: "vety good book 12",
                            sku: '10/100'
                        },
                        {
                            name: 'book 3',
                            quantity: 20,
                            unit_amount: {
                                currency_code: "EUR",
                                value: "1.00"
                            },
                            category: 'PHYSICAL_GOODS',
                            description: "very good book 123",
                            sku: '65/100'
                        }
                        ],
                        soft_descriptor: "G123456789",
                        reference_id: "reference_124",
                    }],
                    // application_context: {
                    //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                    // }
                });
            }}
            // createSubscription={(data, details) => createSubscription(data, details)}
            onApprove={(data, details) => console.log('onApprove', { data, details })}
            onError={(err) => console.error('onError', err)}
            catchError={(err) => console.error('catchError', err)}
            onCancel={(err) => console.error('onCancel', err)}
            options={{
                clientId: paypalKey,
                vault: true,
                currency: 'EUR',
            }}
            style={{
                shape: 'pill',
                color: 'silver',
                layout: 'vertical',
                // label: 'subscribe'

            }}
        />
    );
}

export default Test;