import { useEffect, useState } from "react";
import axios from "axios";
import { getEndpoint } from "../hooks/useApi";
import useCookie from "../hooks/useCookie";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Typography, Chip, Button } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import PayPalBtn from "../components/PayPalBtn";

const PayPage = () => {
  const API_ENDPOINT = getEndpoint();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [plan, setPlan] = useState({});

  const [isNewUser, setIsNewUser] = useState(false);

  const [pageToShow, setPageToShow] = useState("PAY");

  const [emailCookie, updateEmailCookie, removeEmailCookie] = useCookie(
    "email",
    ""
  );
  const [nameCookie, updateNameCookie, removeNameCookie] = useCookie(
    "name",
    ""
  );
  const [tokenCookie, updateTokenCookie, removeToeknCookie] = useCookie(
    "token",
    ""
  );

  const [password, setPassword] = useState("");

  useEffect(() => {
    if (state === null) {
      return navigate("/pay");
    }
    setPlan(state.plan);
  }, [state]);

  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: plan.plan_id,
    });
  };
  const paypalOnError = (err) => {
    // console.log("Error", err)
    setError(true);
  };
  const paypalOnApprove = (data, detail) => {
    // call the backend api to store transaction details
    // console.log("Payapl approved")
    // console.log(data.subscriptionID)
    // console.log(data)
    // console.log(detail)

    axios
      .post(`${API_ENDPOINT}/subscriptions/add-new`, {
        subscription_id: data.subscriptionID,
        plan: plan,
        email: emailCookie,
      })
      .then((response) => {
        // console.log(response)
        // debugger

        const user = response.data.user;

        if (user.isNew) {
          setIsNewUser(true);
          updateEmailCookie(user.email);
          updateNameCookie(user.name);
          updateTokenCookie(user.connection.uuid);
        }

        setPassword(user.password);

        setPageToShow("SECCSES_PROFILE");
      })
      .catch((err) => {
        setError(true);

        console.log(err);
      });
  };

  return (
    <>
      {pageToShow === "PAY" && (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              justifyContent: "center",
              maxWidth: "700px",
              margin: "auto",
            }}
          >
            <Box sx={{ mt: 5, boxShadow: 2, p: 3 }}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: "800",
                  fontSize: "1.6rem",
                  letterSpacing: "normal",
                  lineHeight: 1.2,
                  fontFamily: "Merriweather, serif !important",
                }}
              >
                {plan.title}
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  fontSize: "1.2rem",
                  fontFamily: "Merriweather, serif !important",
                }}
                variant="body2"
              >
                {plan.description}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  mt: 2,
                  fontWeight: "800",
                  fontSize: "1rem",
                  letterSpacing: "normal",
                  fontFamily: "Merriweather, serif !important",
                }}
              >
                le prix {plan.price}
                {plan.currency} Pendant {plan.months_acsses} mois
              </Typography>
              <Box sx={{ mt: 2, textAlign: "center" }}>
                <Chip
                  label={
                    plan.auto_renewal
                      ? `Avec renouvellement automatique`
                      : `Sans renouvellement automatique`
                  }
                  icon={plan.auto_renewal ? <CheckIcon /> : <ClearIcon />}
                  variant="outlined"
                />
              </Box>
            </Box>

            <Box sx={{ mt: 3, p: 3, flexBasis: "100%", textAlign: "center" }}>
              <PayPalBtn
                amount="30"
                currency="ERU"
                createSubscription={paypalSubscribe}
                onApprove={paypalOnApprove}
                catchError={paypalOnError}
                onError={paypalOnError}
                onCancel={paypalOnError}
              />

              <Box sx={{ mt: 2 }}>
                {/* {error &&
                                    <Box className="payment-error red">
                                        Une erreur s'est produite lors du paiement
                                    </Box>
                                } */}
                <Box>
                  En cas de problème :{" "}
                  <a href="mailto:info@metulanews.info">info@metulanews.info</a>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "left",
            }}
          >
            <Box sx={{ mt: 3 }}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/pay");
                }}
              >
                CLIQUER ICI POUR CHOISIR UNE OPTION DIFFERENTE
              </Button>
            </Box>
          </Box>
          {/* <Box sx={{ mt: 3, display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'left' }}>

                        <Box sx={{ mt: 3 }}>
                            <Button variant="contained" onClick={() => {
                                paypalOnApprove({ subscriptionID: '' })
                            }} >
                                test
                            </Button>
                        </Box>
                    </Box> */}
        </>
      )}

      {pageToShow !== "PAY" && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: "700px",
            margin: "auto",
          }}
        >
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontWeight: "800",
                fontSize: "1.6rem",
                letterSpacing: "normal",
                lineHeight: 1.2,
                fontFamily: "Merriweather, serif !important",
              }}
            >
              Votre paiement a réussi, vous êtes maintenant au bénéfice d’un
              abonnement actif à la Ména et disposez dès à présent d’un libre
              accès à tous les articles, passés, présents et à venir publiés par
              notre agence.
            </Typography>
            <Typography
              sx={{
                mt: 2,
                fontSize: "1.2rem",
                fontFamily: "Merriweather, serif !important",
              }}
              variant="body2"
            >
              Ceci représente la formule de l’abonnement que vous venez
              d’acquérir:
            </Typography>
          </Box>
          <Box sx={{ mt: 3, boxShadow: 2, p: 3 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontWeight: "800",
                fontSize: "1.6rem",
                letterSpacing: "normal",
                lineHeight: 1.2,
                fontFamily: "Merriweather, serif !important",
              }}
            >
              {plan.title}
            </Typography>
            <Typography
              sx={{
                mt: 2,
                fontSize: "1.2rem",
                fontFamily: "Merriweather, serif !important",
              }}
              variant="body2"
            >
              {plan.description}
            </Typography>
            {plan.menu_id === 2 && (
              <Typography
                sx={{
                  mt: 2,
                  fontSize: "1.2rem",
                  fontFamily: "Merriweather, serif !important",
                }}
                variant="body2"
              >
                Envoyez à info@metulanews.info la liste des e-mails que vous
                désirez abonner
              </Typography>
            )}
            <Typography
              variant="h5"
              component="div"
              sx={{
                mt: 2,
                fontWeight: "800",
                fontSize: "1rem",
                letterSpacing: "normal",
                fontFamily: "Merriweather, serif !important",
              }}
            >
              le prix {plan.price}
              {plan.currency} Pendant {plan.months_acsses} mois
            </Typography>
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <Chip
                label={
                  plan.auto_renewal
                    ? `Avec renouvellement automatique`
                    : `Sans renouvellement automatique`
                }
                icon={plan.auto_renewal ? <CheckIcon /> : <ClearIcon />}
                variant="outlined"
              />
            </Box>
          </Box>
          {/* <Box sx={{ mt: 3, textAlign: 'center' }}>
                        <Chip
                            label={pageToShow === 'SECCSES_PROFILE' ? `Aller à la page de profil` : `Aller à la connexion`}
                            icon={<AccountCircleIcon />}
                            color="primary"
                            // variant="outlined"
                            onClick={() => {
                                navigate(pageToShow === 'SECCSES_PROFILE' ? `/profile` : `/login`)
                            }}
                        />
                    </Box> */}
          <Box
            sx={{
              mt: 3,
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                mt: 2,
                fontSize: "1.3rem",
                fontFamily: "Merriweather, serif !important",
                lineHeight: "2.3rem",
                textAlign: "center",
              }}
              variant="body2"
            >
              Vous êtes connecté automatiquement au système de la Ména avec
              l’adresse mail de connexion:
              <br />
              <Chip
                sx={{ ml: 1, fontSize: "1.2rem" }}
                label={emailCookie}
                variant="outlined"
                color="primary"
              />
              <br />
              Nous vous avons attribué le mot de passe sécurisé suivant:
              <br />
              <Chip
                sx={{ ml: 1, fontSize: "1.2rem" }}
                label={password}
                variant="outlined"
                color="primary"
              />
              <br />
              Ce binôme d’adresse mail et de mot de passe peut être utilisé sans
              limitation sur tous vos appareils.
              <br />
              Ils vous seront nécessaires afin de vous connecter [Se Connecter]
              en cas de perte de connexion.
              <br />
              Nous vous invitons à enregistrer ces deux informations en lieu
              sûr.
              <br />
              <br />
              Vous pouvez également changer votre mot de passe pour un mot de
              passe de votre choix en cliquant sur le lien suivant apparaissant
              sur votre page de profil:
              <br />
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/profile");
                  if (isNewUser) {
                    window.location.reload();
                  }
                }}
              >
                ACCEDER A VOTRE PAGE DE PROFIL
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PayPage;
