import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';

import { Box, FormControl, OutlinedInput, IconButton, TextField, InputAdornment, Typography, Button, Link } from '@mui/material';
import { red } from '@mui/material/colors';

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import useCookie from "../hooks/useCookie";
import { getEndpoint } from "../hooks/useApi";


const Login = () => {
    const API_ENDPOINT = getEndpoint();
    let params = useParams();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordBox, setShowPasswordBox] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('')
    const inputPasswordElement = useRef(null);
    const [emailSend, setEmailSend] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMesage, setErrorMessage] = useState('');
    const [emailValid, setEmailValid] = useState(false);

    const [emailCookie, updateEmailCookie, removeEmailCookie] = useCookie("email", "");
    const [nameCookie, updateNameCookie, removeNameCookie] = useCookie("name", "");
    const [tokenCookie, updateTokenCookie, removeToeknCookie] = useCookie("token", "");

    useEffect(() => {
        setEmailValid(validateEmail(email))
    }, [email]);

    useEffect(() => {
        if (params.email) {
            setEmail(params.email);
        }

        if (params.token) {

            setLoading(true);
            setErrorMessage('waiting...');


            axios.post(`${API_ENDPOINT}/auth/login/by-link`, {
                email: params.email,
                token: params.token
            })
                .then(res => {
                    updateEmailCookie(params.email);
                    updateNameCookie(res.data.user.name);
                    updateTokenCookie(params.token);

                    navigate('/');
                    window.location.reload();

                }).catch(err => {
                    setLoading(false);
                    setErrorMessage("Erreur de connexion, veuillez réessayer de vous connecter");
                    console.error(err.response.data.message)
                })
        }

    }, [params]);

    const handleClickShowPassword = () => {
        setShowPassword(p => !p)
    }

    const handelSendEmail = () => {
        setErrorMessage('');
        if (email.trim() === '') {
            setErrorMessage("L'E-mail ne peut pas être vide");
            return;
        }

        if (!validateEmail(email)) {
            setErrorMessage("L'E-mail est invalide");
            return;
        }


        // sendMail from server api 
        // then save cookie as token with data from server
        axios.post(`${API_ENDPOINT}/auth/send-magic-link`, {
            email: email
        })
            .then(res => {
                setLoading(false);
                setEmailSend(true);

            }).catch(err => {
                setLoading(false);
                setErrorMessage("Utilisateur introuvable, veuillez vérifier l'adresse e-mail et réessayer");

                console.error(err.response.data.message)
            })
    }


    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handelLoginWithPassword = () => {
        setLoading(true);
        setErrorMessage('waiting...');


        axios.post(`${API_ENDPOINT}/auth/login/by-password`, {
            email: email,
            password: password
        })
            .then(res => {
                updateEmailCookie(res.data.user.email);
                updateNameCookie(res.data.user.name);
                updateTokenCookie(res.data.user.connection.uuid);

                navigate('/');
                window.location.reload();

            }).catch(err => {
                setLoading(false);
                setErrorMessage("L’E-mail ou le mot de passe est incorrect");
                console.error(err.response.data.message)
            })
    }

    return (
        <>
            <Box className="login-container" sx={{ textAlign: 'center', mt: 5, mb: 5 }}>
                <Typography variant="h4">
                    Se connecter / s’identifier sur le site de la Ména [Log in]
                </Typography>
                {
                    !loading && <Box>

                        {!emailSend && !showPasswordBox && <Box sx={{ mt: 3 }}>
                            {/* <Typography variant="h6" sx={{ mt: 3, fontSize: '1rem', maxWidth: '450px', margin: 'auto' }} >
                                Les utilisateurs existants doivent entrer votre adresse e-mail et choisir le moyen de se connecter soit via un lien rapide qui vous sera envoyé par e-mail, soit via un mot de passe
                            </Typography> */}
                            {/* {!emailValid && <Typography variant="h6" sx={{ mt: 3, fontSize: '1rem', maxWidth: '450px', margin: 'auto' }} >
                                Pour vous connecter à un site précédent, entrez votre adresse e-mail
                            </Typography>} */}

                            <Typography variant="h6" sx={{ mt: 3, fontSize: '1.5rem', maxWidth: '450px', margin: 'auto' }} >
                                Insérez votre e-mail ici:
                            </Typography>
                            <TextField
                                type={'email'}
                                sx={{ maxWidth: '450px', mt: 1 }}
                                // label="e-mail    "
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    autoComplete: "on"
                                }}
                            />


                        </Box>
                        }

                        {!showPasswordBox && emailValid &&
                            <Box sx={{ mt: 3 }}>

                                {!emailSend &&
                                    <Box>
                                        {emailValid && <Typography variant="h6" sx={{ fontSize: '2.6rem', fontWeight: 'bolder', maxWidth: '450px', margin: 'auto' }} >
                                            {/* Sélectionnez maintenant la forme de connexion au système,
                                Une première option recommandée consiste à obtenir un lien rapide vers votre boîte de réception
                                La deuxième option consiste à se connecter avec un mot de passe si vous l'avez défini */}
                                            Maintenant :
                                        </Typography>}
                                        <Box sx={{ mt: 3 }}>
                                            <Button sx={{ maxWidth: '550px', fontSize: '1.4rem', display: 'block', margin: 'auto' }} variant="contained" onClick={handelSendEmail}>
                                                Pressez ici pour recevoir sur votre E-mail ci-dessus un &quot;accès magique&quot; pour vous
                                                connecter automatiquement et sans mot de passe sur le site
                                                <br />
                                                SI APRES 20 MINUTES VOUS N'AVEZ PAS RECU DE MAIL, DEMANDEZ VOTRE "ACCES MAGIQUE" A
                                                <span href="mailto:info@metulanews.info" target="_blank" style={{
                                                    display: 'block',
                                                    textDecoration: 'none',
                                                    backgroundColor: '#fff',
                                                    marginTop: '6px',
                                                    marginBottom: '6px',
                                                    padding: '5px 6px',
                                                    fontSize: '15px',
                                                    color: '#000'
                                                }}>
                                                    info@metulanews.info
                                                </span>
                                                {/* 
                                                Obtenez un lien rapide par e-mail
                                                Pour vous connecter grâce à un mot de passe cliquer ici !
                                                Pour vous connecter grâce à un mot de passe cliquer ici ! */}

                                            </Button>
                                        </Box>

                                        {/* <Typography variant="h6" sx={{ mt: 2, fontSize: '1rem' }}>
                                            <Link
                                                component="button"
                                                variant="body2"
                                                onClick={(e) => setShowPasswordBox(true)}
                                            >
                                                Ou Connectez-vous avec un mot de passe
                                            </Link>
                                        </Typography> */}
                                        <Box sx={{ mt: 2, fontSize: '1.4rem', maxWidth: '550px', margin: 'auto' }}>
                                            <Typography variant="h6" sx={{ mt: 2, fontSize: '1.3rem' }}>
                                                -	Attention : la 1ère connexion ne peut se faire qu'avec l' "Accès magique" car vous n'avez pas encore créé de mot de passe.
                                            </Typography>
                                            <Typography variant="h6" sx={{ mt: 2, fontSize: '1.3rem' }}>
                                                -	Une fois que votre "Accès magique" aura été généré, vous pourrez, si vous le désirez, créer un mot de passe qui vous permettra également d’accéder automatiquement aux articles.
                                            </Typography>
                                            <Typography variant="h6" sx={{ mt: 2, fontSize: '1.3rem' }}>
                                                -	Pour fabriquer un mot de passe, appuyer sur "Appuyer ici pour accéder à votre compte" situé à côté de votre nom sur la page principale et suivez les instructions.
                                            </Typography>
                                        </Box>
                                        <Box sx={{ mt: 2 }}>
                                            <Button sx={{ maxWidth: '550px', fontSize: '1.4rem' }} color="success" variant="contained" onClick={() => setShowPasswordBox(true)}>
                                                {/* Ou connectez-vous sur le site en insérant vorte mot de passe dans la fenêtre ci-dessus */}
                                                Pour vous connecter grâce à un mot de passe cliquer ici !
                                                {/* Connectez-vous avec un mot de passe */}
                                            </Button>
                                        </Box>
                                    </Box>
                                }
                                {emailSend &&
                                    <Box>
                                        <Typography variant="h6" sx={{ mt: 2, fontSize: '1.8rem', maxWidth: '850px', margin: 'auto' }}>
                                            Un lien de connexion rapide dit &quot;lien magique&quot; (invisible et automatique) vous a été envoyé par
                                            E-mail à l’adresse <b>{email}</b>.
                                            <br />
                                            Consultez la boîte de réception de votre messagerie voire les spams
                                            <br />
                                            SI APRES 20 MINUTES VOUS N'AVEZ PAS RECU DE MAIL, DEMANDEZ VOTRE "ACCES MAGIQUE" A <a href="mailto:info@metulanews.info">info@metulanews.info</a>
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        }
                        {/* {showPasswordBox && */}
                        <Box sx={{ display: showPasswordBox && emailValid ? `block` : `none`, mt: 3, }}>
                            <Typography variant="h6" sx={{ fontSize: '1.5rem', maxWidth: '450px', margin: 'auto' }} >
                                Insérez votre mot de passe:
                            </Typography>
                            <FormControl sx={{ maxWidth: '450px' }} variant="outlined">
                                {/* <InputLabel htmlFor="outlined-adornment-password">Insérez votre mot de passe</InputLabel> */}
                                <OutlinedInput
                                    ref={inputPasswordElement}
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    // label="Insérez votre mot de passe"
                                    variant="outlined"
                                    inputProps={{
                                        autoComplete: 'on',
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={(e) => {
                                                    setTimeout(() => {
                                                        inputPasswordElement.current.children[0].setSelectionRange(password.length, password.length); inputPasswordElement.current.children[0].focus()
                                                    }, 100);
                                                }}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    } />
                                <Button sx={{ maxWidth: '450px', mt: 3, fontSize: '2rem' }} variant="contained" onClick={handelLoginWithPassword}>
                                    Se connecter
                                </Button>
                            </FormControl>
                            {/* <Box sx={{ mt: 2 }}>
                                <Button sx={{ maxWidth: '450px' }} color="success" variant="contained" onClick={() => setShowPasswordBox(false)}>
                                    Ou se connecter au site de la Ména au moyen d’un &quot;lien magique&quot;
                                </Button>
                            </Box> */}
                            <Typography variant="h6" sx={{ mt: 3 }}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={(e) => setShowPasswordBox(false)}
                                    sx={{ fontSize: '1.4rem' }}
                                >
                                    Revenir à l'étape précédente
                                </Link>
                            </Typography>
                        </Box>
                        {/* } */}

                    </Box>
                }
                {
                    errorMesage !== '' &&
                    <Box >
                        <Typography variant="h6" sx={{ mt: 2, fontSize: '1rem', color: red[500] }}>
                            {errorMesage}
                        </Typography>
                    </Box>
                }
            </Box>
        </>
    )
}

export default Login;