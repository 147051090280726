import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from "react-router-dom";
import convert from 'react-attr-converter';
import { getCookie } from "../hooks/useCookie"
import { getEndpoint } from "../hooks/useApi"
import { Box, Button, Typography, Tooltip, IconButton, Chip } from '@mui/material';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';

import { useNavigate } from "react-router-dom";


const Article = () => {
    const API_ENDPOINT = getEndpoint();

    let { articleId } = useParams();

    const [article, setArticle] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState(false);
    const [onlyDescription, setOnlyDescription] = useState(false);
    const [heightHide, setHeightHide] = useState(150);
    const [visible, setVisible] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        const emailCookie = getCookie("email")
        const tokenCookie = getCookie("token")

        axios.get(`${API_ENDPOINT}/articles/${articleId}?email=${emailCookie}&token=${tokenCookie}`)
            .then(res => {

                if (res.data.error) {
                    setError(true)

                    if (res.data.code === 404) {
                        navigate('/');
                    }
                }

                if (res.data?.article?.onlyDescription) {
                    setOnlyDescription(true);
                    let hideHeight = res.data?.article?.freeContent?.split('<p').length * 25;
                    if (hideHeight > 700) hideHeight = 700;

                    setHeightHide(hideHeight);
                }

                setIsAdmin(res.data?.user?.isAdmin ?? false);
                console.log('res.data?.article', res.data?.article)
                setArticle(res.data?.article);
            })
    }, [articleId]);

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);

        return () => {
            window.removeEventListener('scroll', toggleVisible);
        }
    }, [])

    const DomEl = ({ node }) => {

        if (node === null) {
            return null;
        }

        let tag = node.tag || 'div';
        let attrs = node.attrs || null;
        let children = node.children || null;

        if (attrs !== null) {
            Object.keys(attrs).map(function (key, index) {
                console.log('key', key, attrs[key])
                if (key === 'src' && attrs[key][0] === '/') {
                    attrs[key] = "https://telegra.ph" + attrs.src;                    
                }

                let value = attrs[key];

                if (value === 'true' || value === 'false') {
                    value = value === 'true' ? true : false;
                }

                delete attrs[key];

                attrs[convert(key)] = value
            });
        }


        if (typeof node === 'string' || node instanceof String) {
            return node;
        }

        if (children !== null && children.constructor === Array) {

            if (tag === 'p' &&
                children.length === 1 &&
                ((typeof children[0] === 'string' && children[0].trim() === '') || children[0]?.tag === 'br')) {
                return null;
            }

            children = children.map((child, index) => {
                if (typeof child === 'string' && child.trim() === '') {
                    return null;
                }
                return <DomEl node={child} key={index} />
            });
        }

        return React.createElement(tag, attrs, children);
    }

    const toggleVisible = () => {
        setVisible(document.documentElement.scrollTop > 250)
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handelEditArticle = () => {
        navigate('/admin/post', { state: { edit: true, editArticle: article } });
    }

    return (
        <>
            {
                isAdmin &&
                <div className="add-post-buttons-container">
                    <Button variant="contained" color="primary" onClick={handelEditArticle}>Edit or Delete Article</Button>
                    {/* <Button variant="contained" onClick={handelUpdate}>Update</Button> */}
                    {/* <Button variant="contained" color="error" onClick={handelDelete}>Delete</Button> */}
                </div>
            }
            {/* <Typography sx={{
                fontWeight: '100',
                fontSize: '1.5rem',
                textAlign: 'center',
            }}>
                Ceci est une publicité sponsorisée
            </Typography> */}
            {article.isAds === 1 &&
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Chip
                        label="- Ceci est une publicité sponsorisée -"
                        // icon={<AccountCircleIcon />}
                        // color="primary"
                        // onClick={() => navigate('/login')}
                        sx={{ fontSize: '1.4rem' }}
                    />
                </Box>
            }
            <div className="article-container">
                <div className="sub-header">
                    <Typography variant="h6" component="div" sx={{ fontWeight: '400', fontSize: '0.8rem', letterSpacing: 'normal', lineHeight: 1.1, fontFamily: 'Merriweather, serif !important', marginBottom: '5px' }}>
                        {new Date(article.publishDate).toLocaleDateString("fr-FR",  { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Jerusalem', hour12: false })}
                    </Typography>
                    <span className="bold">
                        {article.title}
                    </span>
                </div>

                {/* <div className="content">
                {article.content && article.content.map((article_node, index) => {
                    return <DomEl node={article_node} key={index} />
                })}
            </div> */}
                {error && <>
                    <div className="error-container">
                        Vous ne pouvez pas accéder aux articles car vous n’êtes pas enregistré. Vous devez vous connecter avec un mail valide assigné à un utilisateur dont le paiement de l’abonnement est à jour. Essayez de vous connecter avec un email différent ou rendez-vous à la <Link to="/pay" className="red"> page de paiement </Link>
                    </div>
                </>}
                {article.headerContent !== null && <div className="content" dangerouslySetInnerHTML={{ __html: article.headerContent }}></div>}
                <div className="content " dangerouslySetInnerHTML={{ __html: article.freeContent }} ></div>

                {onlyDescription && <>

                    <div className="gradient-hide-container">
                        <div className="gradient-hide" style={{ height: heightHide + "px", top: -heightHide + "px" }}>

                        </div>
                    </div>
                    <div className="article-singin-container">
                        <Button href="/profile" color="success" variant="contained" size="large" sx={{ mt: 2, fontSize: '1.3rem', maxWidth: '500px' }}> vous avez un abonnement actif, cliquez ici pour vous connecter</Button>

                        <Button href="/pay" variant="contained" size="large" sx={{ mt: 3, fontSize: '1.3rem', maxWidth: '500px', width: '-webkit-fill-available' }}>Acquérir un abonnement</Button>
                        <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1.4rem', letterSpacing: 'normal', lineHeight: 1.2, mt: 3 }}>
                            Vous ne pouvez pas accéder aux articles car vous n’êtes pas enregistré.
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1.4rem', letterSpacing: 'normal', lineHeight: 1.2, marginTop: 2 }}>
                            Vous devez vous connecter avec un email valide dont le paiement de l'abonnement est à jour pour continuer à lire cet article.
                        </Typography>

                        {/* <Link to="/pay"><Button >Pour lire l'article</Button></Link> */}
                        {/* <Typography variant="h6" component="div" sx={{ fontWeight: '500', fontSize: '1rem', letterSpacing: 'normal', lineHeight: 1.2, marginTop: 3 }}>
                          
                        </Typography> */}
                    </div>
                </>}
                {!onlyDescription &&
                    <>
                        <div className="content " dangerouslySetInnerHTML={{ __html: article.premiumContent }} ></div>
                        {article.footerContent !== null && <div className="content" dangerouslySetInnerHTML={{ __html: article.footerContent }}></div>}

                    </>
                }

                <Box sx={{ marginTop: 2 }}>
                    {article.categories?.map((category) => {
                        return (
                            <Button key={category.id} href={`/${category.id}/1`} variant="text" size="normal" sx={{ marginRight: 1 }}>{`#${category.title}`}</Button>
                        );
                    })}
                </Box>

                <Box sx={{ position: 'fixed', bottom: '50px', marginLeft: '-5%' }}>
                    <Tooltip title="Faites défiler vers le haut" placement="top">
                        <IconButton className="scroll-top-button" onClick={scrollToTop}>

                            <ArrowCircleUpOutlinedIcon fontSize="large"
                                style={{ display: visible ? 'inline' : 'none' }} />
                        </IconButton >
                    </Tooltip>
                </Box>

            </div>
        </>
    )
}

export default Article;