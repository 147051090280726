// useAxios hook

import { useState, useEffect } from 'react';
import axios from 'axios';
import { getEndpoint } from './useApi';

axios.defaults.baseURL = getEndpoint();

const useAxios = () => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(null);

    const fetch = async ({ url, method, body = null, headers = null }) => {
        try {
            setLoading(true)
            setError('');
            let config = {
                url: url, method: method, data: body, headers: headers
            }

            if (method.toUpperCase() === 'GET') {
                config.params = body;
            }
            const result = await axios.request(config);
            setResponse(result.data);
        } catch (error) {
            console.log(error.response);
            if (!!error.response.data.message) {
                setError(error.response.data.message);
            } else {
                setError(error.response.data);
            }
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     fetch();
    // }, [method, url, body, headers]);

    return [response, error, loading, fetch];
};

export default useAxios;