import { useEffect, useState } from "react"

import useAxios from './useAxios'

export default function useLanguages() {
    const [data, setData] = useState([]);

    const [response, error, loading, fetch] = useAxios();

    const fetchLanguages = () => {
        fetch({
            method: 'get',
            url: '/languages',
        })
    }
    useEffect(() => {
        fetchLanguages()
    }, [])

    useEffect(() => {
        if (response !== null) {
            let data = response.data;
            setData(data);
        }
    }, [response]);

    return [data, loading, fetchLanguages];
}
