import React, { useState, useEffect, useRef } from 'react';

import { Box, IconButton, Button, Typography, Tooltip } from '@mui/material';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import { useNavigate, useLocation } from "react-router-dom";

const PreviewPost = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [article, setArticle] = useState({});
    const [heightHide, setHeightHide] = useState(50);
    const [onlyFree, setOnlyFree] = useState(false);
    const [onlyFreeButtonText, setOnlyFreeButtonText] = useState('Show only free content');
    const [visible, setVisible] = useState(false)
    const articleEditebelFree = useRef(null);
    const articleEditebelPremium = useRef(null);

    useEffect(() => {
        if (state?.article) {
            setArticle(state.article)
        }
    }, [state])

    useEffect(() => {
        articleEditebelFree.current.contentEditable = 'true'
        articleEditebelPremium.current.contentEditable = 'true'
    }, [])

    const handelShowOnlyFree = () => {
        setOnlyFree(prev => !prev);
        setHeightHide(article.freeContent.split('<p>').length * 20)
        setOnlyFreeButtonText(onlyFree ? 'Show only free content' : 'Show all content')
    }

    const handelEdit = () => {
        const editArticle = {
            ...article,
            freeContent: articleEditebelFree.current.innerHTML,
            premiumContent: articleEditebelPremium.current.innerHTML
        }

        navigate('/admin/post', { state: { editFromPreview: true, editArticle: editArticle } });
    }

    const toggleVisible = () => {
        setVisible(document.documentElement.scrollTop > 250)
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);

        return () => {
            window.removeEventListener('scroll', toggleVisible);
        }
    }, [])




    return (
        <>
            <div className="add-post-buttons-container">
                <Button variant="contained" color="secondary" onClick={handelEdit} disabled={onlyFree}>Back to Edit</Button>
                <Button variant="contained" color="primary" onClick={handelShowOnlyFree}>{onlyFreeButtonText}</Button>
                {/* <Button variant="contained" color="error" onClick={handelDelete}>Delete</Button> */}
            </div>

            {/* {JSON.stringify(localPost)} */}
            <div className="article-container" >
                <div className="sub-header">
                    <span className="bold red">
                        This is Preview !
                    </span>
                </div>
                <div className="sub-header">
                    <span className="bold">
                        {article.title}
                    </span>
                </div>

                {/* <div className="content">
                    {article.content && article.content.map((article_node, index) => {
                        return <DomEl node={article_node} key={index} />
                    })}
                </div> */}
                {article.showHeader && <div className="content" dangerouslySetInnerHTML={{ __html: article.headerContent }}></div>}
                <div className="content" dangerouslySetInnerHTML={{ __html: article.freeContent }} ref={articleEditebelFree}></div>

                {onlyFree && <>

                    <div className="gradient-hide-container">
                        <div className="gradient-hide" style={{ height: heightHide + "px", top: -heightHide + "px" }}>
                        </div>
                    </div>
                    <div className="article-singin-container">
                        <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1.3rem', letterSpacing: 'normal', lineHeight: 1.2 }}>
                            Vous ne pouvez pas accéder aux articles car vous n’êtes pas enregistré.
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ fontWeight: '600', fontSize: '1.3rem', letterSpacing: 'normal', lineHeight: 1.2, marginTop: 2 }}>
                            Vous devez vous connecter avec un email valide attribué à un utilisateur dont le paiement de l'abonnement est à jour pour continuer à lire cet article.
                        </Typography>

                        {/* <Link to="/pay"><Button >Pour lire l'article</Button></Link> */}
                        <Button href="/pay" variant="contained" size="large" sx={{ marginTop: 3 }}>Vers la page de paiement</Button>
                        <Typography variant="h6" component="div" sx={{ fontWeight: '500', fontSize: '1rem', letterSpacing: 'normal', lineHeight: 1.2, marginTop: 3 }}>
                            Ou essayez de vous connecter avec un autre e-mail en haut de la page
                        </Typography>
                    </div>
                </>}
                {!onlyFree &&
                    <>
                        <div className="content" dangerouslySetInnerHTML={{ __html: article.premiumContent }} ref={articleEditebelPremium}></div>
                        {article.showFooter && <div className="content" dangerouslySetInnerHTML={{ __html: article.footerContent }}></div>}
                    </>
                }

                <Box sx={{ marginTop: 2 }}>
                    {article.categories?.map((category) => {
                        return (
                            <Button key={category.id} href={`category/${category.id}`} variant="text" size="normal" sx={{ marginRight: 1 }}>{`#${category.title}`}</Button>
                        );
                    })}
                </Box>
                <Box sx={{ position: 'fixed', width: '1180px', bottom: '40px', left: '50%', transform: 'translateX(-50%)' }}>
                    <Tooltip title="Faites défiler vers le haut" placement="top">
                        <IconButton className="scroll-top-button" onClick={scrollToTop}>

                            <ArrowCircleUpOutlinedIcon fontSize="large"
                                style={{ display: visible ? 'inline' : 'none' }} />
                        </IconButton >
                    </Tooltip>
                </Box>
            </div >
        </>
    )
}

export default PreviewPost;
