import React, { useState, useEffect } from "react";

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const UserSubscriptions = ({ subscriptions = [], handelRefreshData, handelChangeSubscription, handelGoToPayPal }) => {

    const [allreadyRefresh, setAllreadyRefresh] = useState(false);

    const handelClick = () => {
        handelRefreshData();
        setAllreadyRefresh(true)
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>INDEX</TableCell> */}
                            <TableCell>TYPE D'ABONNEMENT</TableCell>
                            <TableCell >REFERENCE D’ABONNEMENT</TableCell>
                            <TableCell >DATE DE DEBUT</TableCell>
                            <TableCell >FIN DE VALIDITE</TableCell>
                            <TableCell >ACTIF/ANNULE</TableCell>
                            <TableCell >RENOUVELEMENT AUTOMATIQUE OUI/NON</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subscriptions.map((row, index) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {/* <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell> */}
                                <TableCell >{row.plan_title}</TableCell>
                                <TableCell >{row.subscription_id}</TableCell>
                                <TableCell >{new Date(row.start_date).toLocaleDateString()}</TableCell>
                                <TableCell >{new Date(row.end_date).toLocaleDateString()}</TableCell>
                                <TableCell >
                                    {row.is_active ? <CheckIcon /> : <ClearIcon />}
                                </TableCell>
                                <TableCell >
                                    {row.auto_renewal ? <CheckIcon /> : <ClearIcon />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ mt: 3, display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'left' }}>

                <Box>
                    <Button variant="contained" onClick={handelClick} disabled={allreadyRefresh}>
                        Actualiser les données
                    </Button>
                </Box>
                <Box>
                    <Button variant="contained" color="secondary" onClick={handelChangeSubscription}>
                        Ajouter un abonnement
                    </Button>
                </Box>
                <Box>
                    <Button variant="outlined" onClick={handelGoToPayPal}>
                        MON COMPTE PAYPAL
                    </Button>
                </Box>

            </Box>
            {allreadyRefresh &&
                <Box sx={{ mt: 3 }}>
                    Les données sont actualisées
                </Box>
            }
        </>
    )
}

export default UserSubscriptions;