import React, { useState, useEffect } from "react";

import { Box, TextField, Button, Checkbox, FormGroup, FormControlLabel, FormControl, FormHelperText, Typography } from '@mui/material';

import { useNavigate } from "react-router-dom";

const ProfileDetiles = ({ user, handelLogout, handelUpdate, saveState }) => {
    const [email, setEmail] = useState('')
    const [sendNewsletter, setSendNewsletter] = useState(true);
    const [name, setName] = useState('')
    const [password, setPassword] = useState('');
    const [verifayPassword, setVerifayPassword] = useState('');

    const [errorMesage, setErrorMessage] = useState('');
    const [error, setError] = useState(true);

    const navigate = useNavigate();


    useEffect(() => {
        // console.log(saveState);

        setErrorMessage('')
        setError(saveState.loading);

        if (!saveState.loading) {

            if (!saveState.ok) {
                setErr("Une erreur s'est produite lors de l'enregistrement des données")
            } else {
                setErrorMessage('Données enregistrées avec succès')
            }
        }
    }, [saveState])

    // useEffect(() => {
    //     if (password.length >= 6) {
    //         handelChangeValue('verifaypassword', verifayPassword)
    //     }
    // }, [password])

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const setErr = (msg) => {
        setError(msg !== '')
        setErrorMessage(msg)
    }

    useEffect(() => {
        setErr('')

        if (email?.trim() === '') {
            return setErr("L'e-mail ne peut pas être vide");
        }

        if (!validateEmail(email)) {
            return setErr("L'e-mail est invalide");
        }

        if (name.trim() === '') {
            return setErr("laissez vide si vous ne voulez pas changer de mot de passe");
        }

        if (name.trim().length < 3) {
            return setErr("Le nom doit comporter au moins 3 lettres");
        }

        if (password.length > 0 || verifayPassword.length > 0) {

            if (password.length < 6) {
                return setErr("Le mot de passe doit comporter au moins 6 caractères");
            }

            if (verifayPassword !== password) {
                return setErr("Les mots de passe doivent être identiques");
            }
        }
    }, [email, name, password, verifayPassword, sendNewsletter]);

    useEffect(() => {
        setEmail(user.email);
        setSendNewsletter(!user.can_send_newsletter)
        setName(user.name)
    }, [user]);

    return (
        <>
            {/* <Typography variant="h5">
                Détails:
            </Typography> */}
            <FormControl
                required
                error={error}
                component="fieldset"
                variant="standard"
            // sx={{ maxWidth: '60vw' }}
            >
                <Box sx={{ mt: 3, display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'left' }}>
                    <Box sx={{ flexBasis: '100%' }}>
                        {/* <TextField
                            type={'email'}
                            sx={{ width: '300px' }}
                            label="E-mail"
                            variant="outlined"
                            disabled={true}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        // helperText="Cet E-mail est utilisé pour vous connecter/vous identifier sur le site vous pouvez le modifier ici"
                        /> */}
                        <Typography sx={{ fontSize: '1.8rem', fontWeight: 'bolder' }}>
                            Cet E-mail est utilisé pour vous connecter/vous identifier sur le site : {email}
                            {/* vous pouvez le modifier ici */}
                        </Typography>
                    </Box>
                    <Box sx={{}}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={sendNewsletter}
                                        onChange={(e) => setSendNewsletter(e.target.checked)}
                                        name={'Ne pas envoyer les newsletters annonçant la parution de nouveaux articles et diffusant les communiqués de la rédaction'}
                                    />
                                }
                                label="Ne pas envoyer les newsletters annonçant la parution de nouveaux articles et diffusant les communiqués de la rédaction"
                            />
                        </FormGroup>
                    </Box>

                    {/* <Box sx={{ flexBasis: '100%' }}>
                        <TextField
                            type={'text'}
                            sx={{ width: '300px' }}
                            label="NOM OU PSEUDO"
                            variant="outlined"
                            disabled={true}
                            value={name}
                            onChange={(e) => setName(e.target.value)}

                        />
                    </Box> */}
                    <Box sx={{ flexBasis: '100%' }}>
                        <Typography variant="h6" sx={{ fontSize: '1.8rem', fontWeight: 'bolder' }} >
                            Définir ou modifier votre mot de passe :
                        </Typography>

                    </Box>
                    <Box sx={{}}>
                        <TextField
                            type={'text'}
                            sx={{ width: '300px' }}
                            label="MON MOT DE PASSE"
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Box>
                    <Box sx={{}}>
                        <TextField
                            type={'text'}
                            sx={{ width: '300px' }}
                            label="Confirmer le mot de passe"
                            variant="outlined"
                            value={verifayPassword}
                            onChange={(e) => setVerifayPassword(e.target.value)}
                        />
                    </Box>
                </Box>

                <FormHelperText sx={{ mt: 3 }}>{errorMesage}</FormHelperText>

                <Box sx={{ mt: 3, display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'space-between' }}>

                    <Box sx={{ mt: 3, display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'left' }}>
                        <Button variant="contained" onClick={() => handelUpdate(name, password, !sendNewsletter)} disabled={error}>
                            ENREGISTRER LES CHANGEMENTS
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => navigate('/')} disabled={error}>
                            Retourner à la page principale
                        </Button>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Button variant="contained" color="error" onClick={handelLogout}>
                            Logout / Se déconnecter
                        </Button>
                    </Box>
                </Box>

            </FormControl>

        </>
    )
}

export default ProfileDetiles;