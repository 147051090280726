import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Typography,
  Button,
  Box,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import { getCookie } from "../../hooks/useCookie";
import { getEndpoint } from "../../hooks/useApi";

import { useNavigate } from "react-router-dom";

const AdminMails = () => {
  const API_ENDPOINT = getEndpoint();
  let navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [photo, setPhoto] = useState({
    file: null,
    path: "",
    caption: "",
    state: "start",
  });

  const [articleLink, setArticleLink] = useState("");
  const [articleText, setArticleText] = useState("");

  const [sendTo, setSendTo] = useState(1);
  const [hasPreview, setHasPreview] = useState(false);
  const [responeMessage, setResponeMessage] = useState(null);
  const [responeMessageColor, setResponeMessageColor] = useState("#acffb8");

  const handelShowPreview = () => {
    setHasPreview(true);
    handelSend("preview");
  };

  const handelUploadFile = (event) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      // console.log('reader.result', reader.result)
      setPhoto((p) => ({ ...p, path: reader.result, state: "ready" }));
    };

    setPhoto((p) => ({ ...p, file: file, state: "loading" }));
  };

  const handelSend = (to = null) => {
    // alert('Not aviable yet')

    if (to === "preview") {
      to = 0;
    } else {
      to = sendTo;
      setHasPreview(false);
    }

    const email = getCookie("email");
    const token = getCookie("token");

    var formData = new FormData();
    formData.append("image", photo.file);
    formData.append("email", email);
    formData.append("token", token);
    formData.append("to", to);
    formData.append("caption", photo.caption);
    formData.append("content", content);
    formData.append("title", title);
    formData.append("articleLink", articleLink);
    formData.append("articleText", articleText);

    axios
      .post(`${API_ENDPOINT}/mails/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setResponeMessage(res.data.message);
        setResponeMessageColor("#acffb8");
        console.log(res.data);
        if (res.data.redirect) {
          // TODO: replace to mail stats page in the future
          setTimeout(() => navigate("/admin/mails-stats/1"), 1500);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        setResponeMessage(err.response.data.message);
        setResponeMessageColor("#ffacac");
      });
  };

  return (
    <>
      <Typography
        variant="h5"
        component="div"
        sx={{ fontWeight: "bold", mb: 2 }}
      >
        Admin Mails Panel :
      </Typography>
      <Box className="admin-user-options-buttons-container">
        <Button variant="contained" onClick={() => navigate("/admin")}>
          Back to admin panel
        </Button>
      </Box>

      {/* <TextField
                fullWidth
                required
                id="outlined-required"
                label="Telegra.ph link"
                // defaultValue=""
                value={url}
                onChange={(e) => setUrl(e.target.value)}
            /> */}

      <TextField
        fullWidth
        label="Title (showen on mail client)"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        sx={{ mb: 3 }}
      />

      <TextField
        fullWidth
        required
        label="Conetnt"
        multiline
        rows={10}
        value={content}
        onChange={(e) => setContent(e.target.value)}
        sx={{ mb: 3 }}
      />

      {/* <Input

                fullWidth
                required
                label="Image"
                value={content}
                onChange={(e) => setContent(e.target.value)}
            /> */}

      {photo.state === "start" && (
        <>
          <input
            fullWidth
            label="Image"
            accept="image/*"
            // className={classes.input}
            id="contained-button-file"
            multiple={false}
            type="file"
            onChange={(e) => handelUploadFile(e)}
            style={{ display: "none" }}
          />

          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              sx={{ mb: 2 }}
              startIcon={<AddPhotoAlternateIcon />}
              component="span"
            >
              Add photo
            </Button>
          </label>
        </>
      )}

      {photo.state === "loading" && (
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <LinearProgress />
        </Box>
      )}

      {photo.state === "ready" && (
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <img
            src={photo.path}
            alt="photo mail"
            loading="lazy"
            className="admin-email-photo"
            onClick={() =>
              setPhoto((p) => ({
                ...p,
                state: "start",
                path: "",
                file: null,
              }))
            }
          />
        </Box>
      )}

      <TextField
        fullWidth
        label="photo caption"
        value={photo.caption}
        onChange={(e) => setPhoto((p) => ({ ...p, caption: e.target.value }))}
        sx={{ mb: 2 }}
        disabled={photo.state !== "ready"}
      />

      <TextField
        fullWidth
        label="Article Hyper Text"
        value={articleText}
        onChange={(e) => setArticleText(e.target.value)}
        sx={{ mb: 3 }}
      />

      <TextField
        fullWidth
        label="Article Link"
        value={articleLink}
        onChange={(e) => setArticleLink(e.target.value)}
        sx={{ mb: 3 }}
      />

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="send-to-label">Send To</InputLabel>
        <Select
          labelId="send-to-label"
          id="send-to-select"
          value={sendTo}
          label="Send To"
          onChange={(e) => setSendTo(e.target.value)}
        >
          <MenuItem value={1}>All users</MenuItem>
          <MenuItem value={2}>Non Active</MenuItem>
          <MenuItem value={3}>Past Active</MenuItem>
        </Select>
      </FormControl>

      {responeMessage && (
        <Typography
          variant="h6"
          component="h6"
          sx={{
            mb: 2,
            backgroundColor: responeMessageColor,
            borderRadius: "6px",
            padding: "5px 10px",
            fontWeight: "bold",
          }}
        >
          {responeMessage}
        </Typography>
      )}

      <div className="admin-emails-buttons-container">
        <Button
          variant="contained"
          color="secondary"
          onClick={handelShowPreview}
        >
          Preview
        </Button>
        <Button variant="contained" onClick={handelSend} disabled={!hasPreview}>
          {" "}
          Send
        </Button>
        {/* <Button variant="contained" onClick={handelUpdate}>Update</Button> */}
        {/* <Button variant="contained" color="error" onClick={handelDelete}>Delete</Button> */}
      </div>
    </>
  );
};

export default AdminMails;
