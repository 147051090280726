import { useEffect, useState } from "react"

import useAxios from './useAxios'

export default function useAds() {
    const [data, setData] = useState([]);

    const [response, error, loading, fetch] = useAxios();

    const fetchAds = () => {
        fetch({
            method: 'get',
            url: '/articles?ads=1',
        })
    }
    useEffect(() => {
        fetchAds()
    }, [])

    useEffect(() => {
        if (response !== null) {
            let data = response.data;
            setData(data);
        }
    }, [response]);

    return [data, loading, fetchAds];
}
