
import { Card, CardActionArea, CardActions, CardContent, Typography, Chip } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const ArticalCard = ({ plan, selected, handelChanged }) => {

    return (
        <>
            <Card sx={{ boxShadow: selected ? 5 : 1, width: '320px', bgcolor: selected ? `#bcd7f2` : `rgba(0, 0, 0, 0.03)` }}>
                <CardActionArea onClick={() => handelChanged(plan.id)}>
                    <CardContent>
                        <Typography variant="h6" component="div" sx={{ fontWeight: '800', fontSize: '1.6rem', letterSpacing: 'normal', lineHeight: 1.2, fontFamily: 'Merriweather, serif !important' }}>
                            {plan.title}
                        </Typography>

                        <Typography sx={{ mt: 2, fontSize: '1.2rem', fontFamily: 'Merriweather, serif !important' }} variant="body2">
                            {plan.description}
                        </Typography>

                        <Typography variant="h5" component="div" sx={{ mt: 2, fontWeight: '800', fontSize: '1rem', letterSpacing: 'normal', fontFamily: 'Merriweather, serif !important' }}>
                            le prix {plan.price}{plan.currency} Pendant {plan.months_acsses} mois
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ pt: 0, justifyContent: 'center' }}>
                        <Chip
                            label={plan.auto_renewal ? `Avec renouvellement automatique` : `Sans renouvellement automatique`}
                            icon={plan.auto_renewal ? <CheckIcon /> : <ClearIcon />}
                            variant="outlined"
                            sx={{ fontSize: '1rem' }}
                        />
                    </CardActions>
                </CardActionArea>
            </Card>
        </>
    )
}

export default ArticalCard;