import axios from "axios";
import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getEndpoint } from "../../hooks/useApi";
import { getCookie } from "../../hooks/useCookie";

import MailStatsCard from "../../components/MailStatsCard";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

import useMediaQuery from "@mui/material/useMediaQuery";

const Home = () => {
  const API_ENDPOINT = getEndpoint();
  const navigate = useNavigate();
  let { page } = useParams();

  const [mails, setMails] = useState([]);
  const [meta, setMeta] = useState({});

  const [pageTitle, setPageTitle] = useState("mails stats");

  const [nextPage, setNextPage] = useState({
    disabled: true,
    path: "",
  });
  const [previousPage, setPreviousPage] = useState({
    disabled: true,
    path: "",
  });

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    if (meta.page < meta.totalPages) {
      setNextPage({
        disabled: false,
        path: `/admin/mails-stats/${parseInt(meta.page) + 1}`,
      });
    } else {
      setNextPage({
        disabled: true,
        path: "",
      });
    }

    let titleText = "";

    if (meta.page > 1) {
      setPreviousPage({
        disabled: false,
        path: `/admin/mails-stats/${parseInt(meta.page) - 1}`,
      });

      titleText += ` page numéro ${meta.page}`;
    } else {
      setPreviousPage({
        disabled: true,
        path: "",
      });
    }

    setPageTitle(titleText);
  }, [meta]);

  const loadMails = () => {
    setPageTitle(`loading...`);
    setMails([]);

    let url = `${API_ENDPOINT}/mails/stats?page=${page || 1}`;

    axios
      .post(url, {
        email: getCookie("email"),
        token: getCookie("token"),
      })
      .then((res) => {
        const mails = res.data.data;
        console.log(mails);
        setMeta(res.data.meta);

        setMails(mails);
      });
  };

  useEffect(() => {
    loadMails();
  }, [page]);

  return (
    <>
      <Box className="admin-user-options-buttons-container">
        <Button variant="contained" color="secondary" onClick={loadMails}>
          Reload Data
        </Button>
        <Button variant="contained" onClick={() => navigate("/admin")}>
          Back to admin panel
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 3,
          alignItems: "center",
          flexDirection: !matches ? "column-reverse" : "row",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        <Box sx={{ textAlign: "left", marginRight: "auto" }}>
          <Typography
            variant="h2"
            sx={{ fontSize: "1.5rem", fontWeight: "bolder", textAlign: "left" }}
          >
            {pageTitle}
          </Typography>
        </Box>
      </Box>

      <div className="mails-container">
        {mails &&
          mails.map((mail, index) => {
            return <MailStatsCard key={mail.id} mail={mail} />;
          })}
      </div>

      {mails.length === 0 && (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5">Aucun article trouvé</Typography>
        </Box>
      )}
      {mails.length > 0 && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <Button
            disabled={previousPage.disabled}
            onClick={() => {
              navigate(previousPage.path);
              scrollToTop();
            }}
          >
            {`<< `}mails suivants
          </Button>
          <Button
            disabled={nextPage.disabled}
            onClick={() => {
              navigate(nextPage.path);
              scrollToTop();
            }}
          >
            mails précédents{` >>`}
          </Button>
        </Box>
      )}
      {/* <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ mt: 2 }}> */}

      {/* </ButtonGroup> */}
    </>
  );
};

export default Home;
