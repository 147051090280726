import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useNavigate } from "react-router-dom";
import useCookie from "../hooks/useCookie";
import { getEndpoint } from "../hooks/useApi";

import { Box, Typography, Tabs, Tab } from '@mui/material';

import ProfileDetiles from '../components/ProfileDetiles';
import UserSubscriptions from '../components/UserSubscriptions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Profile = () => {
    const API_ENDPOINT = getEndpoint();
    const navigate = useNavigate();

    const [value, setValue] = useState(0);
    const [user, setUser] = useState({});
    const [saveState, setSaveState] = useState({});

    const [emailCookie, updateEmailCookie, removeEmailCookie] = useCookie("email", "");
    const [nameCookie, updateNameCookie, removeNameCookie] = useCookie("name", "");
    const [tokenCookie, updateTokenCookie, removeToeknCookie] = useCookie("token", "");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        // get a token from cookies
        // check if token is valide
        // if not send user to Login page
        loadUser();
    }, [])

    const loadUser = () => {
        axios.get(`${API_ENDPOINT}/users/${emailCookie}?token=${tokenCookie}`)
            .then(res => {

                setUser(res.data.user)
            }).catch(err => {
                console.error(err)
                console.error(err.response.data.message)
                removeNameCookie();
                navigate('/login')
                window.location.reload();
            })
    }

    const handelLogout = () => {
        axios.post(`${API_ENDPOINT}/auth/logout`, {
            email: emailCookie,
            token: tokenCookie
        })
            .then(res => {
                removeEmailCookie();
                removeNameCookie();
                removeToeknCookie();

                navigate('/');
                window.location.reload();
            }).catch(err => {
                console.error(err.response.data.message)
            })
    }

    const handelUpdateUser = (name, password, can_send_newsletter) => {

        // console.log(name)
        // console.log(password)
        // console.log(can_send_newsletter)
        setSaveState({
            loading: true
        })

        axios.put(`${API_ENDPOINT}/users/${emailCookie}`, {
            token: tokenCookie,
            name: name,
            password: password,
            can_send_newsletter: can_send_newsletter
        })
            .then(res => {
                setSaveState({
                    loading: false,
                    ok: true
                })

                updateNameCookie(name);
            }).catch(err => {
                setSaveState({
                    loading: false,
                    ok: false,
                    error: err.response.data.message
                })
                console.error(err.response.data.message)
            })
    }

    const handelRefreshData = () => {
        axios.post(`${API_ENDPOINT}/subscriptions/refresh`, {
            email: emailCookie,
            token: tokenCookie
        })
            .then(res => {
                // setUser(res.data.user)
                loadUser()
            }).catch(err => {
                // console.error(err.response.data.message)
                // navigate('/login')
                // window.location.reload();
            })
    }

    const handelChangeSubscription = () => {
        navigate('/pay');
    }

    return (
        <>
            <Box className="profile-container" >
                {user.name && <Typography variant="h4">
                    profil de {user.name}:
                </Typography>
                }
                <Box
                    sx={{ mt: 3, flexGrow: 1, bgcolor: 'background.paper', }}
                >
                    <Tabs
                        // orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                        <Tab label="DETAIL DE MON COMPTE" sx={{ fontWeight: 'bolder' }} />
                        <Tab label="Historique des abonnements (non modifiable)" sx={{ fontWeight: 'bolder' }} />

                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <ProfileDetiles
                            user={user}
                            handelLogout={handelLogout}
                            handelUpdate={handelUpdateUser}
                            saveState={saveState}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <UserSubscriptions
                            subscriptions={user.subscriptions}
                            handelRefreshData={handelRefreshData}
                            handelChangeSubscription={handelChangeSubscription}
                            handelGoToPayPal={() => { window.open('https://paypal.com') }}
                        />
                    </TabPanel>
                </Box>
            </Box>
        </>
    )
};

export default Profile;