import { useNavigate } from "react-router-dom";

import { Card, CardActionArea, CardContent, Typography } from '@mui/material';


const AdminPanelCard = (panel) => {
    const navigate = useNavigate();

    const goTo = (to) => {
        navigate(`/admin/${to}`);
    }

    return (
        <>
            <Card sx={{ minWidth: 180, boxShadow: 2, mb: 2, textAlign: 'center' }}>
                <CardActionArea onClick={() => goTo(panel.path)}>

                    {/* {panel.icon &&
                        <CardMedia
                            component="img"
                            height="180"
                            image={image}
                            alt={title}
                        />
                    } */}
                    <CardContent>
                        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                            {panel.title}
                        </Typography>
                        {panel.icon}
                    </CardContent>
                </CardActionArea>

            </Card>
        </>
    )
}

export default AdminPanelCard;