import React, { useState, useEffect } from "react";

import { useLocalStorage } from '../../../hooks/useStorage';
import { getCookie } from "../../../hooks/useCookie";
import useAxios from "../../../hooks/useAxios";

import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import { Box, Button, Modal, Typography, TextField, Checkbox, FormControlLabel } from '@mui/material';
// 
// 1 - load users from localStorage
// 2 - id users not in localStorage or date large than one hour then load users again from
// 3 - add button for reload data and save it again to localStorage

// option to add new user

// add table data with sort and search options
// on clicking on user row open modal with edit and view options 
// user info like name and email, user connection, user subscriptions
// options to create new connaction and genarate magic link and password
// option to delete user
// option to add new free subscription to user



const AdminUsersDashboard = () => {
    const [usersAPI, errorUsersAPI, loadingUsersAPI, fetchUsersAPI] = useAxios();
    const [newUserAPI, errorNewUserAPI, loadingNewUserAPI, fetchNewUserAPI] = useAxios();
    const [localUsers, setLocalUsers, removeLocalUsers] = useLocalStorage("local_users", null)
    const [users, setUsers] = useState([]);
    const [lastUpdateFetchingUsers, setLastUpdateFetchingUsers] = useState(null);

    const [columns, setColumns] = useState([]);
    const [loadingData, setLoadingData] = useState(true);

    const [alertMessage, setAlertMessage] = useState(null);
    const [modalNewUserOpen, setModalNewUserOpen] = useState(false);

    const [newUserEmailInput, setNewUserEmailInput] = useState('');
    const [newUserNameInput, setNewUserNameInput] = useState('');
    const [newUserErrorMessage, setNewUserErrorMessage] = useState(null);

    const [modalUserOpen, setModalUserOpen] = useState(false);

    const [user, setUser] = useState({
        is_null: true,
        connections: [],
        subscriptions: []
    });

    const [userEditAlertMessage, setUserEditAlertMessage] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmCloseConnections, setConfirmCloseConnections] = useState(false);

    const [userAPI, errorUserAPI, loadingUserAPI, fetchUserAPI] = useAxios();
    const [editUserAPI, errorEditUserAPI, loadingEditUserAPI, fetchEditUserAPI] = useAxios();
    const [closeUserConnectionsAPI, errorCloseUserConnectionsAPI, loadingCloseUserConnectionsAPI, fetchCloseUserConnectionsAPI] = useAxios();
    const [createUserConnectionAPI, errorCreateUserConnectionAPI, loadingCreateUserConnectionAPI, fetchCreateUserConnectionAPI] = useAxios();
    const [deleteUserAPI, errorDeleteUserAPI, loadingDeleteUserAPI, fetchDeleteUserAPI] = useAxios();

    const [createUserSubscriptionAPI, errorCreateUserSubscriptionAPI, loadingCreateUserSubscriptionAPI, fetchCreateUserSubscriptionAPI] = useAxios();
    const [editUserSubscriptionAPI, errorEditUserSubscriptionAPI, loadingEditUserSubscriptionAPI, fetchEditUserSubscriptionAPI] = useAxios();


    const [userInputs, setUserInputs] = useState({
        id: 0,
        email: '',
        name: '',
        password: '',
        is_admin: false,
        can_send_newsletter: false,
        need_to_remind: false,
        disabled_password: true
    })

    const userConnectionsColumns = [
        { field: 'id', headerName: 'ID', width: 80 },
        {
            field: 'is_active',
            headerName: 'Is Active now',
            description: 'Is connection active',
            // width: 180,
            type: 'boolean',

        },
        {
            field: 'start_date',
            headerName: 'Create at',
            width: 160,
            valueGetter: (params) => new Date(params.row.start_date).toLocaleString(),
        },
        {
            field: 'end_date',
            headerName: 'End at',
            width: 180,
            valueGetter: (params) => !params.row.end_date ? (params.row.is_active ? 'Connection is active' : 'Connection not activated') : new Date(params.row.end_date).toLocaleString(),
        },
        {
            field: 'uuid',
            headerName: 'token',
            width: 300
        },
        {
            field: 'from',
            headerName: 'From',
            width: 100,
            valueGetter: (params) => !params.row.meta_data ? '' : params.row.meta_data?.user_agent?.isDesktop ? 'Desktop' : 'Phone',
        },
        {
            field: 'browser',
            headerName: 'Browser',
            width: 120,
            valueGetter: (params) => !params.row.meta_data ? '' : params.row.meta_data?.user_agent?.browser,
        },

    ];

    const userSubscriptionsColumns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'is_active',
            headerName: 'Is Active now',
            description: 'Is connection active',
            editable: true,

            // width: 180,
            type: 'boolean',
        },
        {
            field: 'start_date',
            headerName: 'Start at',
            width: 180,
            type: 'dateTime',
            editable: true,
            valueGetter: ({ value }) => value && new Date(value),
            // valueGetter: (params) => new Date(params.row.start_date).toLocaleString(),
        },
        {
            field: 'end_date',
            headerName: 'End at',
            width: 180,
            type: 'dateTime',
            editable: true,
            valueGetter: ({ value }) => value && new Date(value),
            // valueGetter: (params) => !params.row.end_date ? '' : new Date(params.row.end_date).toLocaleString(),
        },
        {
            field: 'plan_title',
            headerName: 'Plan',
            width: 200,
            valueGetter: (params) => `${params.row.plan_id} :: ${params.row.plan_title}`,
        },
        {
            field: 'auto_renewal',
            headerName: 'Is auto renewal',
            description: 'Is the subscription an automatic renewal type',
            width: 120,
            type: 'boolean',
        },

        {
            field: 'subscription_id',
            headerName: 'subscription id',
            description: 'subscription id on paypal',
            width: 150,
        },
        {
            field: 'options',
            headerName: 'Options',
            // description: 'options',
            type: 'string',
            // flex: 1,
            width: 100,
            renderCell: (params) => {
                return (params.row.plan_id === 8 && <Button variant="contained" disabled={loadingEditUserSubscriptionAPI} onClick={() => { handelEditUserSubscription(params.row) }}> Save</Button>)
            }

        }
    ];

    useEffect(() => {
        const columnsInit = [
            {
                field: 'id',
                headerName: 'ID',
                description: 'The identification used by the DB',
                type: 'number',

                // width: 65
            },
            {
                field: 'email',
                headerName: 'Email',
                description: 'The identification used by the paypal and for login to system',
                type: 'string',
                flex: 1,
                // width: 300,
                hideable: false
            },
            {
                field: 'name',
                headerName: 'Name',
                description: 'The name of user',
                type: 'string',
                flex: 1,
                // width: 150
            },
            {
                field: 'options',
                headerName: 'Options',
                // description: 'options',
                type: 'string',
                flex: 1,
                // width: 150,
                renderCell: () => {
                    return (<Button variant="contained"> View and Edit</Button>)
                }

            }
        ];

        setColumns(columnsInit);
    }, [])

    useEffect(() => {
        if (!localUsers) return loadUsersFromApi();

        // console.log('localUsers', localUsers)

        const nowTime = new Date().getTime();
        const TIME_TO_UPDATE = 1000 * 60 * 60; // one hour
        if (nowTime > localUsers.last_update + TIME_TO_UPDATE) {
            loadUsersFromApi()
        } else {
            setUsers(localUsers.users);
            setLastUpdateFetchingUsers(localUsers.last_update);
            setLoadingData(false);
        }
    }, [localUsers])

    useEffect(() => {
        if (!usersAPI) return;

        // console.log('usersAPI', usersAPI)
        setLocalUsers({
            last_update: new Date().getTime(),
            users: usersAPI.data
        });

    }, [usersAPI]);

    // useEffect(() => {
    //     if (!errorUsersAPI) return;
    //     console.error(errorUsersAPI);
    // }, [errorUsersAPI])

    useEffect(() => {
        if (!newUserAPI) return;

        setModalNewUserOpen(false);
        setNewUserEmailInput('');
        setNewUserNameInput('');
        loadUsersFromApi();
        showAlertMessage('New User Added Seccsesfully!');

    }, [newUserAPI]);


    const showAlertMessage = (message, time = 5000) => {
        setAlertMessage(message);

        setTimeout(() => {
            setAlertMessage(null);
        }, time);
    }

    const loadUsersFromApi = () => {
        setLoadingData(true);
        setUsers([]);

        fetchUsersAPI({
            method: 'get',
            url: '/admin/users',
            body: {
                email: getCookie('email'),
                token: getCookie('token')
            }
        })
    }

    useEffect(() => {
        if (!errorNewUserAPI) return;
        setNewUserErrorMessage(errorNewUserAPI)
    }, [errorNewUserAPI])


    const handelAddNewUser = () => {
        setNewUserErrorMessage('');
        if (newUserEmailInput === '' || newUserNameInput === '') {
            return setNewUserErrorMessage('Email or Name cannot be empty');
        }

        if (!validateEmail(newUserEmailInput)) {
            return setNewUserErrorMessage('Email not valid');
        }

        fetchNewUserAPI({
            method: 'post',
            url: '/admin/new-user',
            body: {
                email: getCookie('email'),
                token: getCookie('token'),
                user: {
                    email: newUserEmailInput,
                    name: newUserNameInput
                }
            }
        })
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    useEffect(() => {
        if (!userAPI) return;

        console.log(userAPI)

        setUser(userAPI.data);
    }, [userAPI])

    useEffect(() => {
        if (user.is_null) return;


        setUserInputs({
            id: user.id,
            email: user.email,
            name: user.name,
            password: (user.is_set_password ? '****' : 'No Set'),
            is_admin: user.is_admin,
            can_send_newsletter: user.can_send_newsletter,
            need_to_remind: user.need_to_remind,
            disabled_password: true
        })
    }, [user])

    const handelOpenUserModal = (user_id) => {

        // reset values
        setConfirmDelete(false);

        // reset user 
        setUser({
            is_null: true,
            connections: [],
            subscriptions: []
        });

        // reset user inputs 
        setUserInputs({
            id: 0,
            email: '',
            name: '',
            password: '',
            is_admin: false,
            can_send_newsletter: false,
            need_to_remind: false,
            disabled_password: true
        })

        //get User Data From API
        //TODO:: 
        fetchUserAPI({
            method: 'get',
            url: `/admin/user/${user_id}`,
            body: {
                email: getCookie('email'),
                token: getCookie('token'),
            }
        })

        //Show Modal With
        setModalUserOpen(true);
    }

    // useEffect(() => {

    //     console.log(userInputs)

    // }, [userInputs])

    useEffect(() => {
        if (!editUserAPI) return;

        showUserEditAlertMessage(`User updated seccsesfully!`);

        // update full list
        const index = users.findIndex(u => u.id === user.id);

        let newUsers = [...users];
        newUsers[index] = {
            id: user.id,
            email: editUserAPI.user.email,
            name: editUserAPI.user.name
        };
        setUsers(newUsers)

        // update localstorage
        setLocalUsers({
            last_update: new Date().getTime(),
            users: newUsers
        });

    }, [editUserAPI])

    useEffect(() => {
        if (!errorEditUserAPI) return;

        console.log(errorEditUserAPI)
        showUserEditAlertMessage(`Error saving user:: ${errorEditUserAPI}`)


    }, [errorEditUserAPI])



    const handelEditUser = () => {
        // TODO
        fetchEditUserAPI({
            method: 'put',
            url: `/admin/user/${user.id}`,
            body: {
                email: getCookie('email'),
                token: getCookie('token'),
                user: userInputs
            }
        })
    }

    const handelDeleteUser = () => {
        setConfirmDelete(true);
    }

    const handelConfirmDeleteUser = () => {

        // TODO
        showUserEditAlertMessage(`This function not working yet`)

    }

    const handelCloseUserConnections = () => {
        setConfirmCloseConnections(true);
    }

    useEffect(() => {
        if (!errorCloseUserConnectionsAPI) return;

        console.log(errorCloseUserConnectionsAPI)
        showUserEditAlertMessage(`Error close user connections:: ${errorCloseUserConnectionsAPI}`)
    }, [errorCloseUserConnectionsAPI])

    useEffect(() => {
        if (!closeUserConnectionsAPI) return;

        console.log(closeUserConnectionsAPI)

        setUser(u => ({ ...u, connections: closeUserConnectionsAPI.connections }))

        showUserEditAlertMessage(`user connections close seccsesfully!`)
    }, [closeUserConnectionsAPI])


    const handelConfirmCloseUserConnections = () => {
        // showUserEditAlertMessage('User Connections closed')
        fetchCloseUserConnectionsAPI({
            method: 'post',
            url: `/admin/close-user-connections`,
            body: {
                email: getCookie('email'),
                token: getCookie('token'),
                user_id: user.id
            }
        })
    }

    const hanelResetPassword = () => {
        setUserInputs(u => {
            return {
                ...u,
                password: (Math.random() + 1).toString(36).substring(6),
                disabled_password: false
            }
        })
    }

    const showUserEditAlertMessage = (message, time = 5000) => {
        setUserEditAlertMessage(message);

        setTimeout(() => {
            setUserEditAlertMessage(null);
        }, time);
    }

    useEffect(() => {
        if (!errorCreateUserConnectionAPI) return;

        console.log(errorCreateUserConnectionAPI)
        showUserEditAlertMessage(`Error create user connection:: ${errorCreateUserConnectionAPI}`)
    }, [errorCreateUserConnectionAPI])

    useEffect(() => {
        if (!createUserConnectionAPI) return;

        console.log(createUserConnectionAPI)

        setUser(u => ({ ...u, connections: createUserConnectionAPI.connections }))

        const connection = createUserConnectionAPI.connection // get from API

        const link = getConnactionMagicLink(connection);

        copyTextToClipboard(link)
            .then(() => {
                showUserEditAlertMessage('User Connection created and magic link copy to clipboard')
            })
            .catch((err) => {
                showUserEditAlertMessage(`User connection create seccsesfully!`)

                console.log(err);
            });

    }, [createUserConnectionAPI])

    const handelCreateNewUserConnection = () => {

        fetchCreateUserConnectionAPI({
            method: 'post',
            url: `/admin/create-user-connection`,
            body: {
                email: getCookie('email'),
                token: getCookie('token'),
                user_id: user.id
            }
        })
    }

    const getConnactionMagicLink = (connection) => {
        return `https://menapress.org/login/${user.email}/${connection.uuid}`
    }

    useEffect(() => {
        if (!errorCreateUserSubscriptionAPI) return;

        console.log(errorCreateUserSubscriptionAPI)
        showUserEditAlertMessage(`Error create user subscription:: ${errorCreateUserSubscriptionAPI}`)
    }, [errorCreateUserSubscriptionAPI])

    useEffect(() => {
        if (!createUserSubscriptionAPI) return;

        console.log(createUserSubscriptionAPI)

        setUser(u => ({ ...u, subscriptions: createUserSubscriptionAPI.subscriptions }))

        showUserEditAlertMessage('User subscription created seccsesfully! please dont forget to edit end date')

    }, [createUserSubscriptionAPI])

    const handelCreateNewUserSubscription = () => {

        fetchCreateUserSubscriptionAPI({
            method: 'post',
            url: `/admin/create-user-subscription`,
            body: {
                email: getCookie('email'),
                token: getCookie('token'),
                user_id: user.id
            }
        })
    }

    useEffect(() => {
        if (!errorEditUserSubscriptionAPI) return;

        console.log(errorEditUserSubscriptionAPI)

        setUser(u => ({ ...u, subscriptions: createUserSubscriptionAPI.subscriptions }))

        showUserEditAlertMessage(`Error edit user subscription:: ${errorEditUserSubscriptionAPI}`)
    }, [errorEditUserSubscriptionAPI])

    useEffect(() => {
        if (!editUserSubscriptionAPI) return;

        console.log(editUserSubscriptionAPI)

        showUserEditAlertMessage('User subscription edited seccsesfully!')

    }, [editUserSubscriptionAPI])

    const handelEditUserSubscription = (userSubscription) => {
        console.log(new Date(userSubscription.start_date).toISOString().slice(0, 19).replace('T', ' '))
        console.log('handelEditUserSubscription', userSubscription)

        fetchEditUserSubscriptionAPI({
            method: 'post',
            url: `/admin/edit-user-subscription`,
            body: {
                email: getCookie('email'),
                token: getCookie('token'),
                subscription: userSubscription
            }
        })
    }

    return (
        <>
            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                Admin Users Panel :
            </Typography>

            <Box className="admin-user-options-buttons-container">
                <Typography variant="h5" component="div">
                    last update data at: <span style={{ fontWeight: 'bold' }}>
                        {new Date(lastUpdateFetchingUsers).toLocaleString()}
                    </span>
                </Typography>
                <Button variant="contained" color="secondary" onClick={loadUsersFromApi}>Reload Data</Button>
                <Button variant="contained" onClick={() => setModalNewUserOpen(true)}>Add New user</Button>
            </Box>

            <DataGrid
                getRowId={(row) => row.id}
                autoHeight
                rows={users}
                columns={columns}
                rowsPerPageOptions={[10, 25, 50, 100]}
                disableMultipleSelection={true}
                onSelectionModelChange={(newSelectionModel) => {
                    if (newSelectionModel[0] !== undefined) {
                        // TODO::
                        // open user modal
                        handelOpenUserModal(newSelectionModel[0])
                    }
                }}
                initialState={{
                    sorting: {
                        // sortModel: [{ field: 'rating', sort: 'desc' }],
                    },
                    pagination: {
                        pageSize: 10,
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            payerId: false,
                        },
                    }
                }}
                components={{ Toolbar: CustomToolbar }}
                loading={loadingData}
            />

            {alertMessage && <Box sx={{ boxShadow: 3, p: 3, mt: 2, bgcolor: '#eeeeeea1' }}>
                <Typography variant="h5" component="h5" >
                    {alertMessage}
                </Typography>
            </Box>
            }

            {/* new User Modal */}
            <Modal
                keepMounted
                open={modalNewUserOpen}
                onClose={() => {

                    setModalNewUserOpen(false)
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    // border: '2px solid #000',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Add new user
                    </Typography>
                    <TextField id="outlined-basic" fullWidth label="Email" variant="outlined" value={newUserEmailInput} onChange={(e) => setNewUserEmailInput(e.target.value)} sx={{ mt: 2 }} type="email" />
                    <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" value={newUserNameInput} onChange={(e) => setNewUserNameInput(e.target.value)} sx={{ mt: 2 }} />
                    {newUserErrorMessage && <Typography id="keep-mounted-modal-title" variant="p" component="p" sx={{ color: 'red', mt: 2 }}>
                        {newUserErrorMessage}
                    </Typography>
                    }


                    <Button variant="contained" onClick={handelAddNewUser} sx={{ mt: 2 }} disabled={loadingNewUserAPI}>Save</Button>

                </Box>
            </Modal>

            {/* User view And edit modal */}
            <Modal
                keepMounted
                open={modalUserOpen}
                onClose={() => { setModalUserOpen(false) }}

            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'calc(100% - 100px)',
                    maxWidth: 1200,
                    bgcolor: 'background.paper',
                    // border: '2px solid #000',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '80vh',
                    overflowX: 'scroll',
                }}>
                    <Typography variant="h5" component="h2">
                        View and Edit User:
                    </Typography>



                    <Box sx={{ boxShadow: 4, p: 2, borderRadius: 3, mt: 2 }}>
                        <Typography variant="h6" component="h2" >
                            general information:
                        </Typography>

                        <Box sx={{ display: 'flex', gap: "20px", mt: 3 }}>

                            {/* id */}
                            <TextField
                                label="ID on Database"
                                variant="outlined"
                                value={userInputs.id}
                                sx={{ width: 180 }}
                                type="text"
                                disabled={true}
                            />

                            {/* email */}
                            <TextField
                                label="Email"
                                variant="outlined"
                                value={userInputs.email}
                                sx={{ width: 350 }}
                                type="email"
                                onChange={(e) => setUserInputs(u => ({ ...u, email: e.target.value }))}
                            />

                            {/* name */}
                            <TextField
                                label="Name"
                                variant="outlined"
                                value={userInputs.name}
                                sx={{ width: 350 }}
                                type="text"
                                onChange={(e) => setUserInputs(u => ({ ...u, name: e.target.value }))}

                            />

                        </Box>
                        <Box sx={{ display: 'flex', gap: "20px", mt: 3 }}>
                            {/* password input */}
                            <TextField
                                label="Password"
                                variant="outlined"
                                value={userInputs.password}
                                sx={{ width: 180 }}
                                type="text"
                                disabled={userInputs.disabled_password}
                                onChange={(e) => setUserInputs(u => ({ ...u, password: e.target.value }))}

                            />

                            {/* password reset button */}
                            <Button
                                variant="contained"
                                onClick={hanelResetPassword}
                            >Reset Password</Button>
                        </Box>

                        <Box sx={{ display: 'flex', gap: "20px", mt: 3 }}>
                            {/* can_send_newsletter input */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userInputs.can_send_newsletter}
                                        onChange={(e) => setUserInputs(u => ({ ...u, can_send_newsletter: e.target.checked }))}
                                        name="send newsletter" />
                                }
                                label="Send newsletter"
                            />

                            {/* is_admin input */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userInputs.is_admin}
                                        onChange={(e) => setUserInputs(u => ({ ...u, is_admin: e.target.checked }))}
                                        name="is admin" />
                                }
                                label="Set as Admin"
                            />

                            {/* need_to_remind input */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userInputs.need_to_remind}
                                        onChange={(e) => setUserInputs(u => ({ ...u, need_to_remind: e.target.checked }))}
                                        name="resubscroption reminder" />
                                }
                                label="Resubscroption reminder"
                            />
                        </Box>
                        <Box className="admin-user-options-buttons-container">
                            <Button variant="contained" onClick={handelEditUser} disabled={loadingUserAPI || loadingEditUserAPI}>Save</Button>
                            <Button color="warning" variant="contained" onClick={handelDeleteUser} disabled={loadingUserAPI || loadingEditUserAPI}>Delete</Button>
                            <Button color="error" variant="contained" onClick={handelConfirmDeleteUser} disabled={!confirmDelete || loadingEditUserAPI}>Confirm Delete</Button>
                        </Box>
                    </Box>

                    {userEditAlertMessage && <Box sx={{ boxShadow: 3, p: 3, mt: 2, bgcolor: '#9f9f9fa1', borderRadius: 3 }}>
                        <Typography variant="h5" component="h5" >
                            {userEditAlertMessage}
                        </Typography>
                    </Box>
                    }

                    <Box sx={{ boxShadow: 4, p: 2, borderRadius: 3, mt: 2 }}>
                        <Typography variant="h6" component="h2" >
                            Connections:
                        </Typography>

                        <DataGrid
                            sx={{ mt: 1 }}
                            getRowId={(row) => row.id}
                            autoHeight
                            rows={user?.connections}
                            columns={userConnectionsColumns}
                            rowsPerPageOptions={[5]}
                            disableMultipleSelection={true}
                            onSelectionModelChange={(newSelectionModel) => {
                                if (newSelectionModel[0] !== undefined) {
                                    const connection = user.connections.find(c => c.id === newSelectionModel[0]);

                                    if (connection.end_date === null && !connection.is_active) {

                                        const link = getConnactionMagicLink(connection);

                                        copyTextToClipboard(link)
                                            .then(() => {
                                                showUserEditAlertMessage('Magic Link Copy to Clipboard!', 2000)
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                            });
                                    }
                                }
                            }}
                            initialState={{
                                sorting: {
                                    // sortModel: [{ field: 'rating', sort: 'desc' }],
                                },
                                pagination: {
                                    pageSize: 5,
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        // id: false,
                                    },
                                }
                            }}
                            // components={{ Toolbar: CustomToolbar }}
                            loading={loadingUserAPI}
                        />

                        <Box className="admin-user-options-buttons-container" >
                            <Button variant="contained" onClick={handelCreateNewUserConnection} disabled={loadingNewUserAPI || loadingCloseUserConnectionsAPI}>Create connection</Button>

                            <Button color="warning" variant="contained" onClick={handelCloseUserConnections} disabled={loadingUserAPI || loadingCloseUserConnectionsAPI}>Close All connections</Button>
                            <Button color="error" variant="contained" onClick={handelConfirmCloseUserConnections} disabled={!confirmCloseConnections || loadingCloseUserConnectionsAPI}>Confirm close</Button>
                        </Box>
                    </Box>
                    <Box sx={{ boxShadow: 4, p: 2, borderRadius: 3, mt: 2 }}>
                        <Typography variant="h6" component="h2" >
                            subscriptions:
                        </Typography>

                        <DataGrid
                            sx={{ mt: 1 }}
                            getRowId={(row) => row.id}
                            autoHeight
                            rows={user?.subscriptions}
                            columns={userSubscriptionsColumns}
                            rowsPerPageOptions={[3]}
                            disableMultipleSelection={true}
                            // onSelectionModelChange={(newSelectionModel) => {
                            //     if (newSelectionModel[0] !== undefined) {
                            //         const connection = user.connections.find(c => c.id === newSelectionModel[0]);

                            //         if (connection.end_date === null && !connection.is_active) {

                            //             const link = getConnactionMagicLink(connection);

                            //             copyTextToClipboard(link)
                            //                 .then(() => {
                            //                     showUserEditAlertMessage('Magic Link Copy to Clipboard!', 2000)
                            //                 })
                            //                 .catch((err) => {
                            //                     console.log(err);
                            //                 });
                            //         }
                            //     }
                            // }}
                            initialState={{
                                sorting: {
                                    // sortModel: [{ field: 'rating', sort: 'desc' }],
                                },
                                pagination: {
                                    pageSize: 3,
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        // id: false,
                                    },
                                }
                            }}
                            // components={{ Toolbar: CustomToolbar }}
                            loading={loadingUserAPI}
                        />
                    </Box>
                    <Box className="admin-user-options-buttons-container" >
                        <Button variant="contained" onClick={handelCreateNewUserSubscription} disabled={loadingNewUserAPI || loadingCreateUserSubscriptionAPI}>Create subscription</Button>

                        {/* <Button color="warning" variant="contained" onClick={handelCloseUserConnections} disabled={loadingUserAPI || loadingCloseUserConnectionsAPI}>Close All connections</Button>
                        <Button color="error" variant="contained" onClick={handelConfirmCloseUserConnections} disabled={!confirmCloseConnections || loadingCloseUserConnectionsAPI}>Confirm close</Button> */}
                    </Box>

                </Box>
            </Modal>
        </>
    )
}

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

// This is the function we wrote earlier
async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}
export default AdminUsersDashboard;