import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { Box, Button, Modal, Typography, TextField } from '@mui/material';

import { getCookie } from "../../hooks/useCookie"
import { getEndpoint } from "../../hooks/useApi"
import useCategories from '../../hooks/useCategories';

import './admin.css';

const modalStyle = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
};

const Categories = () => {

    const API_ENDPOINT = getEndpoint();
    const email = getCookie("email")
    const token = getCookie("token")

    const [tasks, setTasks] = useState({});
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const [titleInput, setTitleInput] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [categories, loading, reloadData] = useCategories()

    useEffect(() => {
        if (loading) return setRows([])

        setRows(categories)
    }, [loading])
    useEffect(() => {
        Init()
        document.getElementById('container').style = 'max-width: 1280px;';
        return () => {
            document.getElementById('container').style = '';
        }
    }, [])

    // change save button disabled or not enabled
    useEffect(() => {
        setSaveButtonDisabled(!(Object.keys(tasks).length > 0))
    }, [tasks])

    const Init = () => {

        // Init columns
        const columnsInit = [
            {
                field: 'id',
                headerName: 'ID',
                description: 'The identification used by the DB',
                type: 'number',
                width: 100
            },
            {
                field: 'title',
                headerName: 'Title',
                description: 'The title of category',
                type: 'string',
                editable: true,
                valueSetter: setTitle,
                width: 500
            },
            {
                field: 'active',
                headerName: 'Active',
                description: 'Active mean if the category work',
                valueSetter: setActive,
                type: 'boolean',
                editable: true,
                width: 65
            },
        ];

        setColumns(columnsInit);

        // Load rows from DB
        // loadData();
    };

    const HandelAddTask = (userId, newValue) => {
        setTasks((t) => {
            let tempTasks = { ...t };
            tempTasks[userId] = newValue;
            return tempTasks;
        })
    }

    const setTitle = (params) => {
        const newValue = { ...params.row, title: params.value }
        HandelAddTask(params.row.id, newValue);
        return newValue;
    }

    const setActive = (params) => {
        const newValue = { ...params.row, active: params.value };
        HandelAddTask(params.row.id, newValue);
        return newValue;
    }

    const handelSaveData = () => {
        // Run for all tasks and save to DB
        // then reload Data and set tasks to empty list
        Object.keys(tasks).forEach((id) => {
            const task = tasks[id];

            // Update category to DB
            axios.put(`${API_ENDPOINT}/categories/${id}`, {
                email: email,
                token: token,
                title: task.title,
                active: task.active
            }).then(res => {
                if (res.data.error) {
                    console.log(res.data.message)
                    return;
                }
            })

        })

        setSaveButtonDisabled(true);
        setTasks({})

        return;
    }

    const handelReloadData = () => {

        // Run reload function
        reloadData();
        return;
    }

    const handelAddNew = () => {
        if (titleInput === '') {
            return setErrorMessage('Title cannot be empty');
        }

        // Add user to DB
        axios.post(`${API_ENDPOINT}/categories`, {
            email: email,
            token: token,
            title: titleInput
        })
            .then(res => {
                if (res.data.error) {
                    console.log(res.data.message)
                    return;
                }

                handleClose();
                // loadData();
                reloadData();
            })

        return;
    }

    // const loadData = () => {

    //     setRows([]);
    //     setLoading(true);


    //     axios.get(`${API_ENDPOINT}/categories?s=${email}`)
    //         .then(res => {

    //             if (res.data.error) {
    //                 // navigate('/')
    //                 console.log(res.data.message)
    //                 return;
    //             }

    //             setRows(res.data.data);
    //             setLoading(false)
    //         })
    // }

    return (
        <div style={{ width: '100%' }}>
            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                Admin Categories Panel :
            </Typography>

            <Box className="admin-user-options-buttons-container">
                <Button sx={{}} variant="contained" onClick={handelSaveData} disabled={saveButtonDisabled}>Save Data</Button>
                <Button variant="contained" color="secondary" onClick={handelReloadData}>Reload Data</Button>
                <Button variant="contained" onClick={handleOpen}>Add New Category</Button>
            </Box>
            <Modal
                keepMounted
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                        Add new Category
                    </Typography>
                    <TextField id="outlined-basic" fullWidth label="Title" variant="outlined" value={titleInput} onChange={(e) => setTitleInput(e.target.value)} sx={{ mt: 2 }} type="text" />
                    {errorMessage && <Typography id="keep-mounted-modal-title" variant="p" component="p" sx={{ color: 'red', mt: 2 }}>
                        {errorMessage}
                    </Typography>
                    }


                    <Button variant="contained" onClick={handelAddNew} sx={{ mt: 2 }}>Save</Button>

                </Box>
            </Modal>

            <DataGrid
                getRowId={(row) => row.id}
                autoHeight
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[10, 25, 50, 100]}
                initialState={{
                    sorting: {
                        // sortModel: [{ field: 'rating', sort: 'desc' }],
                    },
                    pagination: {
                        pageSize: 10,
                    },
                    columns: {
                        // columnVisibilityModel: {
                        //     id: true,


                        // },
                    }
                }}
                components={{ Toolbar: CustomToolbar }}
                loading={loading}
            // onCellEditCommit={(p) => setTimeout(() => {
            //     const row = rows.find((r) => r.id === p.id)
            //     console.log(row)
            // }, 500)}
            />
        </div>
    );
}

export default Categories;


function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            {/* <GridToolbarExport /> */}
        </GridToolbarContainer>
    );
}