import { useEffect } from "react";

import axios from "axios";

import { getCookie } from "../../hooks/useCookie";
import { getEndpoint } from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";

import AdminPanelCard from "../../components/AdminPanelCard";

import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import BarChartIcon from "@mui/icons-material/BarChart";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CategoryIcon from "@mui/icons-material/Category";
const panels = [
  {
    key: 1,
    title: "Users",
    path: "users",
    icon: <PeopleIcon fontSize="large" />,
  },
  {
    key: 2,
    title: "Articles",
    path: "post",
    icon: <PostAddIcon fontSize="large" />,
  },
  {
    key: 3,
    title: "Emails",
    path: "mails",
    icon: <EmailIcon fontSize="large" />,
  },
  {
    key: 5,
    title: "Emails Stats",
    path: "mails-stats/1",
    icon: <BarChartIcon fontSize="large" />,
  },
  {
    key: 4,
    title: "Categories",
    path: "categories",
    icon: <CategoryIcon fontSize="large" />,
  },
];

const AdminDashboard = () => {
  const API_ENDPOINT = getEndpoint();
  const navigate = useNavigate();

  useEffect(() => {
    // get a token from cookies
    // check if token is valide
    // if not send user to Login page
    loadUser();
  }, []);

  const loadUser = () => {
    const emailCookie = getCookie("email");
    const tokenCookie = getCookie("token");
    axios
      .get(`${API_ENDPOINT}/users/${emailCookie}?token=${tokenCookie}`)
      .then((res) => {
        if (!res.data.user.is_admin) navigate("/");
      })
      .catch((err) => {
        navigate("/");
      });
  };

  return (
    <div className="admin-dashboard-container">
      {panels.map((panel) => (
        <AdminPanelCard {...panel} />
      ))}
    </div>
  );
};

export default AdminDashboard;
