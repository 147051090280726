import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Hidden, Chip } from '@mui/material';

import { getCookie } from "../hooks/useCookie";
import useCategories from "../hooks/useCategories";
import useStaticsArticles from "../hooks/useStaticsArticles";

import GoogleTranslate from "./GoogleTranslate"

import './Header.css';

const Header = () => {
    const navigate = useNavigate();

    const [date, setDate] = useState('...')
    const [categories] = useCategories(true)
    const [staticsArticle] = useStaticsArticles();
    // const language = getCookie('language') ?? 1;

    const name = getCookie('name');

    useEffect(() => {
        calcDate()

    }, []);

    useEffect(() => {

    })

    // useEffect(() => {
    //     if (!window.google.translate) return;
    //     console.log(window.google.translate)
    //     const G = new window.google.translate.TranslateElement(
    //         {
    //             pageLanguage: 'fr',
    //             includedLanguages: 'en,de,it',
    //             layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
    //         }
    //         , 'google_translate_element')

    //     console.log(G)

    // }, [window.google.translate])

    useEffect(() => {
        const interval = setInterval(() => calcDate, 1000);
        return () => clearInterval(interval);
    }, []);

    const calcDate = () => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Jerusalem', hour12: false };
        let dateText = new Date().toLocaleTimeString("fr-FR", options);

        dateText = dateText.substring(0, dateText.length - 3) + ' en Israël'

        dateText = dateText.charAt(0).toUpperCase() + dateText.slice(1);

        setDate(dateText)
    }

    // useEffect(() => {
    //     window.addEventListener('scroll', this.handleScroll);
    //     return () => window.removeEventListener('scroll', this.handleScroll);
    // }, []);

    // const handleScroll = (e) => {
    //     let scrollTop = e.srcElement.body.scrollTop,
    //         itemTranslate = Math.min(0, scrollTop / 3 - 60);

    //     this.setState({
    //         transform: itemTranslate
    //     });
    // }

    return (
        <div className="header">
            <div className="header-logo-and-date-container">
                <div className="mena_header_logo_box">
                    <Link to="/" className="not-underline">
                        <img src="/images/mena_logo.png" alt="" className="mena_header_logo" />
                    </Link>
                </div>
                <div className="header-date">
                    {date}
                </div>
            </div>
            <div className="header-email-and-google-container">
                <div className="email-auth">
                    {name === null ?
                        <Chip
                            label="Se connecter"
                            icon={<AccountCircleIcon />}
                            color="primary"
                            onClick={() => navigate('/login')}
                            sx={{
                                fontSize: '1.6rem',
                                whiteSpace: 'break-spaces',
                                height: 'fit-content',
                                padding: '5px 0',
                                textAlign: 'center'
                            }}
                        />
                        :
                        <Chip
                            label={`Hi, ${name} / cliquer ici pour accéder aux paramètres de votre compte`}
                            icon={<AccountCircleIcon />}
                            color="primary"
                            onClick={() => navigate('/profile')}
                            sx={{
                                fontSize: 'large',
                                whiteSpace: 'break-spaces',
                                height: 'fit-content',
                                padding: '5px 0',
                                textAlign: 'center'
                            }}
                        />
                    }
                </div>
                <GoogleTranslate />
            </div>

            {/* <div className="text-logo">
                <Link to="/" className="not-underline">
                    <span className="red">Me</span>tula <span className="red">N</span>ews <span className="red">A</span>gency <span className="red">©</span>
                </Link>
            </div> */}


            <Hidden smDown={true}>

                <div className="header-categories">
                    {categories.map((category) => (<Link to={`${category.id}/1`} className="header-category" key={category.id}>
                        {category.title}
                    </Link>)
                    )}
                </div>

                <div className="header-statics">
                    {staticsArticle.map((article) => (<Link to={`/articles/${article.path}`} className="header-static" key={article.id}>
                        {article.title}
                    </Link>)
                    )}
                </div>
            </Hidden>


        </div >
    )
}



export default Header;
