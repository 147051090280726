import { useState, } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import useAds from "../hooks/useAds";
import { useNavigate } from "react-router-dom";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Ads() {
    const navigate = useNavigate();

    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [ads] = useAds();
    const maxSteps = ads.length;

    if (maxSteps === 0) return null;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handelOpenAds = () => {
        navigate(`/articles/${ads[activeStep].path}`);
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'left', mt: 2, borderRadius: 4, boxShadow: 2, position: 'relative' }}>


            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                style={{ width: '100%', height: '100px', overflow: 'hidden' }}
                // onClick={() => console.log(activeStep)}
                interval={5000}
                className="AutoPlaySwipeableViews"
            >
                {ads.map((step, index) => (
                    <div key={step.id}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box sx={{ display: 'flex' }}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: '100px',
                                        display: 'block',
                                        maxWidth: 400,
                                        overflow: 'hidden',
                                        width: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '12px 0 0 12px'
                                        // cover: 
                                    }}
                                    src={step.image}
                                    alt={step.title}
                                />
                                <Box sx={{ flexGrow: 1 }}>

                                    <Typography sx={{
                                        maxHeight: '45px',
                                        height: '45px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        px: 2,
                                        pt: 1
                                    }}>
                                        {ads[activeStep].description.replaceAll(/<br>/g, ' ').substring(0, 200)}
                                    </Typography>
                                    {/* <MobileStepper
                                    steps={maxSteps}
                                    position="static"
                                    activeStep={activeStep}
                                variant="text" /> */}

                                    <Box sx={{
                                        display: 'flex',
                                        px: 2,
                                        justifyContent: 'space-between'
                                    }}>
                                        <Button size="small" >
                                            Publicité
                                        </Button>
                                        <MobileStepper
                                            steps={maxSteps}
                                            position="static"
                                            activeStep={activeStep}
                                            variant="dots"
                                            className="MobileStepper"
                                            sx={{ background: 'transparent', transition: 'opacity 280ms linear', opacity: 0 }}
                                            nextButton={
                                                <Button
                                                    size="small"
                                                    onClick={handleNext}
                                                    disabled={activeStep === maxSteps - 1}
                                                >
                                                    Next
                                                    {theme.direction === 'rtl' ? (
                                                        <KeyboardArrowLeft />
                                                    ) : (
                                                        <KeyboardArrowRight />
                                                    )}
                                                </Button>
                                            }
                                            backButton={
                                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                                    {theme.direction === 'rtl' ? (
                                                        <KeyboardArrowRight />
                                                    ) : (
                                                        <KeyboardArrowLeft />
                                                    )}
                                                    Back
                                                </Button>
                                            }
                                        />
                                        <Button size="small" onClick={handelOpenAds}>
                                            pour plus de détails
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}
                    </div>
                ))}

            </AutoPlaySwipeableViews>


        </Box >
    );
}

export default Ads;