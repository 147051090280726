import { PayPalButton } from "react-paypal-button-v2";
import React from 'react';

export function PayPalBtn(props) {
    const { amount, currency, createSubscription, onApprove, catchError, onError, onCancel } = props;
    // const paypalKey = "AeqdqOSNCAUbsrumuTllj2X0aqNePqUuVmPQWMQlfiXZzzxJ9fAznefjnrilZJZ0fo3ui4Tu_Zx7KI32" // TEST old
    // const paypalKey = "ATn9A60_ObOBAENjRwZLRckn7eSJwz9R8ss_lQm1BwU-neXTmkChgVbvFkel-foLcTtmfTtaPStGbccI" // production old
    // const paypalKey = "AR2EfgzL15e5xznJ70ECk4yUqPv4zYzkQtKSJkX3Xvho4_goonhYWp5v7MuTb9J6BIfW2U2FOZDcV-k6"  // TEST NEW
    const paypalKey = "AfD6kXbLi1wiv9ubY5WaH0djRpQBghcXDgaRhWzhJ7MHhIHLZOc8dw2M-SrLfxVTcKXlY0lFQuM7V6Tk" // production new

    return (
        <PayPalButton
            amount={amount}
            currency={currency}
            createSubscription={(data, details) => createSubscription(data, details)}
            onApprove={(data, details) => onApprove(data, details)}
            onError={(err) => onError(err)}
            catchError={(err) => catchError(err)}
            onCancel={(err) => onCancel(err)}
            options={{
                clientId: paypalKey,
                vault: true
            }}
            style={{
                shape: 'pill',
                color: 'silver',
                layout: 'vertical',
                label: 'subscribe'

            }}
        />
    );
}
export default PayPalBtn;