import React, { useEffect, useState } from "react";

const GoogleTranslate = () => {
    const [show, setShow] = useState(false)
    useEffect(() => {
        if (!window.google.translate) return;
        setTimeout(() => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: 'fr',
                    includedLanguages: 'fr,en,de,it,es',
                    layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
                }
                , 'google_translate_element')

            setShow(true)
        }, 1050)

    }, [window.google.translate])

    useEffect(() => {
        // in some cases, the google translate script adds a style to the opening html tag.
        // this added style disables scrolling.
        // the next 3 lines removes this added style in order to re-enable scrolling.
        if (window.document.scrollingElement.hasAttribute("style")) {
            window.document.scrollingElement.setAttribute("style", "");
        }
    });

    return (

        <div className={`google_transalte_container ${show ? 'show_google_transalte' : ''}`}>
            Translate / Übersetzen / Tradurre / Traducir : <div id="google_translate_element"></div>
        </div>
    );
};

export default GoogleTranslate;