import { useNavigate } from "react-router-dom";

import { Card, CardActionArea, CardActions, CardMedia, CardContent, Typography } from '@mui/material';

const ArticalCard = ({ title, description, path, image, largeImage, date }) => {
    const navigate = useNavigate();

    const getCleanDescription = (description) => {

        let d = description.trim();
        if (d.startsWith('<br>')) d = d.substring(4);
        if (d.endsWith('</br>')) d = d.substring(0, d.length - 4)

        return d;
        // let descriptionArray = description.split('<br>',);

        // if (descriptionArray[0].trim() == '<br>') descriptionArray

        // console.log(description);

        // console.log(description.split('<br>').map((line) => { if (line.trim() === '') return '<br>'; return line }))



        // return description.split('<br>').map((line) => { if (line.trim() === '') return '<br>'; return line }).join('<br>');
    }

    return (
        <>
            <Card sx={{ boxShadow: 2 }} className="article-card">
                <CardActionArea onClick={() => { navigate(`/articles/${path}`); }}>

                    {image &&
                        <CardMedia
                            component="img"
                            height={largeImage ? "420" : "280"}
                            image={image}
                            alt={title}
                            style={{ objectFit: 'contain ' }}
                        />
                    }
                    <CardContent>
                        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Word of the Day
                    </Typography> */}
                      <Typography variant="h6" component="div" sx={{ fontWeight: '400', fontSize: '0.8rem', letterSpacing: 'normal', lineHeight: 1.1, fontFamily: 'Merriweather, serif !important', marginBottom: '5px' }}>
                        {new Date(date).toLocaleDateString("fr-FR",  { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Jerusalem', hour12: false })}
                    </Typography>
                        <Typography variant="h6" component="div" sx={{ fontWeight: '800', fontSize: '1.6rem', letterSpacing: 'normal', lineHeight: 1.2, fontFamily: 'Merriweather, serif !important' }}>
                            {title}
                        </Typography>
                        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        adjective
                    </Typography> */}
                        {/* maxHeight: '300px', overflowX: "auto", */}
                        <Typography sx={{ mt: 2, fontSize: '1.2rem', fontFamily: 'Merriweather, serif !important' }} variant="body2" dangerouslySetInnerHTML={{ __html: getCleanDescription(description) }}>
                            {/* {description} */}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ pt: 0 }}>
                        <Typography variant="button" component="a" sx={{ color: '#1976d2', ml: 2, fontFamily: 'Merriweather, serif !important' }}>
                            Pour lire l'article
                        </Typography>
                        {/* <Button >Pour lire l'article</Button> */}
                    </CardActions>
                </CardActionArea>

            </Card>
        </>
    )
}

export default ArticalCard;