import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  CardContent,
  Typography,
  Collapse,
  IconButton,
  Box,
} from "@mui/material";

import { useState } from "react";

import { styled } from "@mui/material/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const MailSatatsCard = ({ mail }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ boxShadow: 2 }} className="article-card-search">
      <CardActionArea onClick={handleExpandClick}>
        <CardContent>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: "800",
              fontSize: "1.4rem",
              letterSpacing: "normal",
              lineHeight: 1.2,
              fontFamily: "Merriweather, serif !important",
              //   mt: 1,
            }}
          >
            {mail.title}
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: "400",
              fontSize: "0.8rem",
              letterSpacing: "normal",
              lineHeight: 1.1,
              fontFamily: "Merriweather, serif !important",
              mt: 1,
            }}
          >
            ID: {mail.id} {"   |  DATE: "}{" "}
            {new Date(mail.create_at).toLocaleString()}
          </Typography>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
              mx: "auto",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                width: "200px",
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Send to SendGrid
                </Typography>
                <Typography variant="h5" component="div">
                  {mail.sent}
                </Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                width: "200px",
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Delivered Successfully
                </Typography>
                <Typography variant="h5" component="div">
                  {mail.delivered}
                </Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                width: "200px",
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Opens
                </Typography>
                <Typography variant="h5" component="div">
                  {mail.open}
                </Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                width: "200px",
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Clicks
                </Typography>
                <Typography variant="h5" component="div">
                  {mail.clicked}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </CardContent>
        <CardActions sx={{ p: 0, m: 0 }}>
          <ExpandMore
            expand={expanded}
            // onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography
              sx={{
                mt: 2,
                fontSize: "1rem",
                fontFamily: "Merriweather, serif !important",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
              variant="body2"
              dangerouslySetInnerHTML={{ __html: mail.content }}
            ></Typography>
          </CardContent>
        </Collapse>
      </CardActionArea>
    </Card>
  );
};

export default MailSatatsCard;
