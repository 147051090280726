import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "./pages/Home";
import Article from "./pages/Article";
import PayPage from "./pages/PayPage";
import PlansPage from "./pages/Plans";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Test from "./components/Test";

import AdminDashboard from "./pages/admin/Dashboard";
// import AdminUsers from "./pages/admin/Users";
import AdminUsersDashboard from "./pages/admin/Users/Dashboard";
import AdminMails from "./pages/admin/Mails";
import AdminPost from "./pages/admin/Post";
import AdminPreviewPost from "./pages/admin/PreviewPost";
import AdminCategories from "./pages/admin/Categories";
import AdminMailsStats from "./pages/admin/MailsStats";

import Header from "./components/Header";

function App() {
  return (
    <BrowserRouter>
      <div className="container" id="container">
        <Header />
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Navigate to="/0/1" />} />
            <Route path="/:type/:page" element={<Home />} />
            <Route path="admin" element={<AdminDashboard />} />
            <Route path="admin/users" element={<AdminUsersDashboard />} />
            <Route path="admin/post" element={<AdminPost />} />
            <Route path="admin/mails" element={<AdminMails />} />
            <Route
              path="admin/mails-stats/:page"
              element={<AdminMailsStats />}
            />
            <Route path="admin/preview-post" element={<AdminPreviewPost />} />
            <Route path="admin/categories" element={<AdminCategories />} />
            <Route path="pay" element={<PlansPage />} />
            <Route path="pay/invoice" element={<PayPage />} />
            <Route path="login" element={<Login />} />
            <Route path="login/:email/:token" element={<Login />} />
            <Route path="profile" element={<Profile />} />
            <Route path="articles" element={<Navigate to="/" />} />
            <Route path="articles/:articleId" element={<Article />} />
            <Route path="test" element={<Test />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
