import { useEffect, useState } from "react"

import useAxios from './useAxios'

export default function useStaticsArticles() {
    const [data, setData] = useState([]);

    const [response, error, loading, fetch] = useAxios();

    const fetchStaticsArticles = () => {
        fetch({
            method: 'get',
            url: '/articles?static=1',
        })
    }
    useEffect(() => {
        fetchStaticsArticles()
    }, [])

    useEffect(() => {
        if (response !== null) {
            let data = response.data;
            setData(data);
        }
    }, [response]);

    return [data, loading, fetchStaticsArticles];
}
