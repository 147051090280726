import { useEffect, useState } from "react"

import useAxios from './useAxios'

export default function useCategories(actives = false) {
    const [data, setData] = useState([]);

    const [response, error, loading, fetch] = useAxios();

    const fetchCategories = () => {
        fetch({
            method: 'get',
            url: '/categories',
        })
    }
    useEffect(() => {
        fetchCategories()
    }, [])

    useEffect(() => {
        if (response !== null) {
            let data = response.data;
            if (actives) {
                data = data.filter(data => data.active)
            }
            setData(data);
        }
    }, [response]);

    return [data, loading, fetchCategories];
}
