import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { useNavigate } from "react-router-dom";
import { getEndpoint } from "../hooks/useApi";

import { Box, Button, Typography } from '@mui/material';

import SubscriptionPlanBox from '../components/SubscriptionPlanBox'

const PlansPage = () => {
    const API_ENDPOINT = getEndpoint();
    const navigate = useNavigate();

    const [selectedPlan, setSelectedPlan] = useState(null)
    const [plans, setPlans] = useState([])

    const goToPay = () => {
        navigate('/pay/invoice', { state: { plan: selectedPlan } })
    }

    const handelSelectionChanged = (plan_id) => {
        const plan = plans.find(p => p.id === plan_id);

        setSelectedPlan(plan.id === selectedPlan?.id ? null : plan)
    }

    useEffect(() => {
        axios.get(`${API_ENDPOINT}/subscriptions/plans`)
            .then(res => {
                setPlans(res.data.data);
            })
    }, [])

    return (
        <>
            <Typography variant="h6" component="div" sx={{ fontWeight: '800', fontSize: '1.6rem', letterSpacing: 'normal', lineHeight: 1.2, fontFamily: 'Merriweather, serif !important' }}>
                Choisissez la formule d’abonnement qui vous convient :
            </Typography>
            <Box sx={{ display: 'flex', gap: '30px', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '800px', margin: 'auto', mt: 3 }}>
                {
                    plans.map(plan => {
                        if (plan.menu_id === 1)
                            return (
                                <SubscriptionPlanBox
                                    key={plan.id}
                                    plan={plan}
                                    selected={selectedPlan?.id === plan.id}
                                    handelChanged={handelSelectionChanged}
                                />
                            )
                    })
                }
            </Box>
            <Typography variant="h6" component="div" sx={{ mt: 3, fontWeight: '800', fontSize: '1.6rem', letterSpacing: 'normal', lineHeight: 1.2, fontFamily: 'Merriweather, serif !important' }}>
                Les formules d’abonnements volontaires :
            </Typography>
            <Box sx={{ mt: 3, display: 'flex', gap: '30px', flexWrap: 'wrap', justifyContent: 'center  ' }}>
                {
                    plans.map(plan => {
                        if (plan.menu_id === 2)
                            return (
                                <SubscriptionPlanBox
                                    key={plan.id}
                                    plan={plan}
                                    selected={selectedPlan?.id === plan.id}
                                    handelChanged={handelSelectionChanged}
                                />
                            )
                    })
                }
            </Box>
            <Box sx={{ mt: 3, display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'right' }}>

                <Box sx={{ mt: 3 }}>
                    <Button variant="contained" onClick={goToPay} disabled={selectedPlan === null} sx={{ fontSize: '2rem' }}>
                        Procéder au paiement
                    </Button>
                </Box>
            </Box>
        </>
    )
}



export default PlansPage;